import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 205;

export const useStyles = makeStyles((theme) => ({
    layoutContainer: {
        display: 'block',
        height:'100%',
        background: '#F4F5F5 0% 0% no-repeat padding-box',
        " & .MuiDrawer-root": {
            " & .MuiPaper-root": {
                background: '#1D1D1D',
                " & .MuiList-padding": {
                    paddingTop: 24,
                }
            }
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        flexGrow: 1,
        padding: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    contentShift: {
        padding: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
    mainContainer: {
        paddingTop: 70,
        opacity: 1,
        height: "100%",
        width: "100%",
        display:'flex',
        flexDirection:'column',
        overflow:"scroll",
        position:"absolute",
        zIndex:0,
        background: '#F4F5F5 0% 0% no-repeat padding-box',
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'grey 0% 0% no-repeat padding-box',
            borderRadius: '4px',
        },
        "& .HvContainer-root": {
            padding: '0px',
            height: window.screen.height
        }

    },
    container: {
        paddingTop: 64,
        paddingBottom: 0,
        flex:1,
        overflowY:'auto',
        paddingLeft: 0,
        paddingRight: 0,
       
        '@media (min-width:320px) and (max-width:599px)': {
            paddingTop: theme.spacing(6.8),
        },
        "& .main-container": {
            width: '100%',
            overflow: 'auto',
            overflowX: 'hidden',
            background: '#F4F5F5 0% 0% no-repeat padding-box',
            " & .main-block": {
                // padding: '16px',
                background: '#F4F5F5',
                height: '100vh' - '64px',
                " & .main-header": {
                    padding: '24px 24px 0px 24px',
                    marginBottom: '8px',
                    " & .main-label": {
                        fontSize: '18px',
                        color: '#000',
                        fontWeight: 600,
                        cursor: 'pointer',
                        height: 34,
                        "@media(min-width:320px) and (max-width: 767px)": {
                            fontSize: 16,
                        }
                    }
                },
                " & .welcome-img": {
                    height: '50vh',
                    "@media(min-width:1890px) and (max-width: 2400px)": {
                        height: '52vh',
                    },
                    "@media(min-width:2560px) and (max-width: 3820px)": {
                        height: '56vh',
                    },
                    "@media(min-width:3830px) and (max-width: 5100px)": {
                        height: '60vh',
                    },
                    "@media(min-width:5120px)": {
                        height: '62vh',
                    },
                    " & .welcome-msg": {
                        color: '#fff',
                        fontSize: '24px',
                        textAlign: 'center',
                        paddingTop: '24px',
                        "@media(min-width:320px) and (max-width: 767px)": {
                            fontSize: 16,
                            paddingTop: 24,
                        }
                    },
                    " & .halo-msg": {
                        color: '#fff',
                        fontSize: '36px',
                        textAlign: 'center',
                        fontWeight: 600,
                        "@media(min-width:320px) and (max-width: 767px)": {
                            fontSize: 24,
                        }
                    },
                    " & .footer-content": {
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        marginBottom: '16px',
                        color: '#fff',
                        " & >span": {
                            "@media(min-width:320px) and (max-width: 767px)": {
                                fontSize: 10,
                            }
                        },
                        " & .footer-label": {
                            fontSize: '18px',
                            fontWeight: 600,
                            marginBottom: 8,
                            " & > span": {
                                borderBottom: '3px solid #bd0912',
                            },
                            "@media(min-width:320px) and (max-width: 767px)": {
                                fontSize: 14,
                            }
                        },
                    },
                    " & .button-container": {
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 16,
                        " & .overview-button-block": {
                            padding: '8px 16px 8px 16px',
                            background: '#bd0912',
                            color: '#fff',
                            borderRadius: 4,
                            marginRight: 16,
                            fontSize: 12,
                            marginTop: 8,
                            cursor: 'pointer',
                            " & .icon": {
                                marginRight: 8,
                            },
                            "@media(min-width:320px) and (max-width: 767px)": {
                                width: '166px'
                            }
                        },
                        " & .guide-button-block": {
                            padding: '8px 16px 8px 16px',
                            background: '#bd0912',
                            color: '#fff',
                            borderRadius: 4,
                            marginRight: 16,
                            fontSize: 12,
                            marginTop: 8,
                            cursor: 'pointer',
                            " & .icon": {
                                marginRight: 8,
                            },
                            "@media(min-width:320px) and (max-width: 767px)": {
                                width: '124px'
                            }
                        },
                        "@media(min-width:320px) and (max-width: 767px)": {
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }
                    },
                },
                " & .cards-container": {
                    marginTop: 1,
                    height: 239,
                    display: 'flex',
                    justifyContent: 'space-between',
                    "@media(min-width:320px) and (max-width: 767px)": {
                        display: 'flex',
                        flexDirection: 'column',
                        height: 580,
                    },
                    "@media(min-width:1285px) and (max-width: 1490px)": {
                        height: 273,
                    },
                    "@media(min-width:1491px) and (max-width: 1620px)": {
                        height: 315,
                    },
                    "@media(min-width:1621px) and (max-width: 1800px)": {
                        height: 341,
                    },
                    "@media(min-width:1890px) and (max-width: 2400px)": {
                        height: 374,
                    },
                    "@media(min-width:2560px) and (max-width: 3820px)": {
                        height: 470,
                    },
                    "@media(min-width:3830px) and (max-width: 5100px)": {
                        height: 664,
                    },
                    "@media(min-width:5120px)": {
                        height: 860,
                    }
                },
                " & .card-block": {
                    position: 'absolute',
                    top: '51%',
                    " & .block-heading": {
                        fontSize: 16,
                        color: '#fff',
                        padding: '16px',
                        letterSpacing: '.3px',
                        fontWeight: 500,
                        "@media(min-width:320px) and (max-width: 860px)": {
                            fontSize: 16,
                            padding: '12px',
                        },
                    },
                    " & .block-content": {
                        fontSize: 14,
                        color: '#fff',
                        paddingLeft: '16px',
                        paddingRight: '8px',
                        "@media(min-width:320px) and (max-width: 860px)": {
                            fontSize: 14,
                            paddingLeft: '12px',
                        }
                    },
                },
                " & .card-hover": {
                    top: '10%',
                    "@media(min-width:1285px) and (max-width: 1490px)": {
                        top: '26%',
                    },
                    "@media(min-width:1491px) and (max-width: 1620px)": {
                        top: '38%',
                    },
                    "@media(min-width:1621px) and (max-width: 1800px)": {
                        top: '38%',
                    },
                    "@media(min-width:1890px) and (max-width: 2400px)": {
                        top: '42%',
                    },
                    "@media(min-width:2560px) and (max-width: 3820px)": {
                        top: '46%',
                    },
                    "@media(min-width:3830px)": {
                        top: '46%',
                    },

                }

            },
            " & .main-block-expanded-form": {
                paddingLeft: 56,
            },
            "@media(min-width:320px) and (max-width: 767px)": {
                " & .unauthorized-block": {
                    background: "#F4F5F5",
                }
            },
            " & .main-block-collapsed-form": {
                paddingLeft: '0px',
            },
            "@media(min-width:320px) and (max-width:599px)": {
                height: 'calc(100vh - 54px)',
            }
        },
    },
    scPortalContainer: {
        padding: 0
    }
}));

