import { HvCardContent, HvTag, HvTypography, HvOverflowTooltip, HvBox } from "@hitachivantara/uikit-react-core";
import { htmlToText } from "html-to-text";
import React from "react";
import { useStyles } from "./style";
import { Tag, Layers, Host } from "@hitachivantara/uikit-react-icons";
import { HvLoading } from "@hitachivantara/uikit-react-core";
import _ from "lodash";
import { PropTypes } from 'prop-types';
import { withRouter } from "react-router";
import { PHYSICAL_HARDWARE, VIRTUAL_HARDWARE } from "constants/common";
import { LAB_AS_A_SERVICE, ON_DEMAND_LAB, PREP_LAB_ONDEMAND } from "constants/labs";
const CardContent = (props) => {
    const { lab, runLab } = props
    const classes = useStyles();
    const redirect = (url, labId, enviroment_id) => {
        props.history.push(
            {
                pathname: url,
                state: {
                    labId: labId,
                    environment_id:enviroment_id
                }
            }
        );
    }

    const renderLabTypeIcon = (tag_description) => {
        switch (tag_description) {
            case ON_DEMAND_LAB:
                return (
                    <>
                        <HvBox style={{display:"flex"}}>
                            <Host />
                            <HvTypography style={{ alignSelf: 'center' }}>{VIRTUAL_HARDWARE}</HvTypography>
                        </HvBox>
                    </>
                )
            case PREP_LAB_ONDEMAND:
                return (
                    <>
                        <HvBox style={{display:"flex"}}>
                            <Host />
                            <HvTypography style={{ alignSelf: 'center' }}>{VIRTUAL_HARDWARE}</HvTypography>
                        </HvBox>
                    </>
                )
            case LAB_AS_A_SERVICE:
                return (
                    <>
                        <HvBox style={{display:"flex"}}>
                            <Host />
                            <HvTypography style={{ alignSelf: 'center' }}>{PHYSICAL_HARDWARE}</HvTypography>
                        </HvBox>
                    </>
                )
               
            default:
                return (
                    <>
                       <HvBox style={{display:"flex"}}>
                            <Host iconSize="XS" />
                            <HvTypography variant="caption1" style={{ alignSelf: 'center' }}>NA</HvTypography>
                        </HvBox>
                    </>
                )
        }
    }

    return <HvCardContent style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: "100%"
    }}>

        {runLab !== lab.id ?
            <HvBox style={{ width: '-webkit-fill-available', height: "auto", gap:2}} >
                <HvBox style={{ width: '-webkit-fill-available' }}>
                    <HvTypography className={classes.description} variant="normalText">{htmlToText(lab.description && lab.description.length > 100 ? lab.description.slice(0, 100) + "..." : lab.description, {
                        wordwrap: 1 - 0
                    })}</HvTypography>
                </HvBox>
                <HvTypography variant="link" className={classes.knowMore} onClick={() => { redirect('/labdetailview', lab?.lab ? lab?.lab : lab?.id, lab?.enviroment_id) }}>Details</HvTypography>
                <HvBox style={{display:"flex"}}>
                    <HvBox style={{display:"flex", columnGap:"3px"}} width='100%' >
                        <Layers />
                        <HvTypography style={{ alignSelf: 'center' }}>{lab?.categories && lab.categories.length > 0 ? lab.categories.length > 1 ? `${lab.categories[0]} +${lab.categories.length - 1}` : lab.categories[0] : 'NA'}</HvTypography>
                    </HvBox>
                </HvBox>
                <HvBox style={{display:"flex" }} width={"100%"}>
                    {lab.labtags && lab.labtags.length > 0 ?
                        <>
                            <HvBox style={{display:"flex", columnGap:"1px"}}>
                                <Tag />
                                <HvBox  style={{ display:"flex", flexWrap: "wrap", justifyContent: "flex-start"}}>
                                    {
                                        lab.labtags.length > 4 ? lab.labtags.slice(0, 3).map((t1) => {
                                            return (
                                                <>
                                                    <HvTag className={classes.tagLabel} label={t1.length > 6 ? <HvOverflowTooltip data={t1}></HvOverflowTooltip> : t1} type="categorical" color={_.sample(["cat2", "cat3", "cat4", "cat5", "cat6"])} style={{ alignSelf: 'center' }}  />
                                                </>
                                            )
                                        }) :
                                            lab.labtags.map((t1) => {
                                                return (
                                                    <>

                                                        <HvTag className={classes.tagLabel} label={t1.length > 6 ? <HvOverflowTooltip data={t1}></HvOverflowTooltip> : t1} type="categorical" color={_.sample(["cat2", "cat3", "cat4", "cat5", "cat6"])} style={{ alignSelf: 'center' }} />


                                                    </>
                                                )
                                            })
                                    }
                                </HvBox>
                            </HvBox>

                        </>
                        :
                        <></>}
                </HvBox>
                <HvBox style={{display:"flex" , columnGap:"4px"}}>
                    {
                        renderLabTypeIcon(lab.tag_description)
                    }

                </HvBox>
            </HvBox> :
            <HvBox >
                <HvLoading small />
                <HvTypography style={{ paddingTop: 27 }}>Please wait as your lab is being configured</HvTypography>
            </HvBox>}
    </HvCardContent>
}

CardContent.propTypes = {
    history: PropTypes.any
};
export default withRouter(CardContent);