import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "utils/theme";
import Accordion from "components/accordianNested";
import FAQService from "services/admin/masters/FAQService";
import { MSG_DELETE_CATEGORY_SUCCESS, MSG_DELETE_QUESTION_SUCCESS } from "constants/admin/masters/faq";
import { buildQueryParams } from "utils/common";
import { toast } from "react-toastify";

const faqServiceObj = new FAQService();

const List = (props) => {
    const classes = useStyles();
    const [faqData, setFaqData] = useState([]);
    const { url: masterUrl } = props.match;
    const userRole = masterUrl !== '/faq' ? true : false
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "FAQ", },
        ]
    };

    useEffect(() => {
        getAllFaqList();
    }, []);

    const getAllFaqList = () => {
        let urlParams = { is_deleted: false }
        faqServiceObj.getAllFaqList(buildQueryParams(urlParams)).then(response => {
            if (response) {
                setFaqData(response.results);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const deleteFaqCategory = (categoryId) => {
        faqServiceObj.deleteFaqCategory(categoryId).then(response => {
            toast.success(response.message || MSG_DELETE_CATEGORY_SUCCESS);
            getAllFaqList();
        }).catch(error => {
            console.log(error);
        })
    }

    const deleteFaqQuestion = (questionId) => {
        faqServiceObj.deleteFaqQuestion(questionId).then(response => {
            toast.success(response.message || MSG_DELETE_QUESTION_SUCCESS);
            getAllFaqList();
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <>
            <Grid container spacing={1} className={classes.outerContainer}>
                <Grid item xs={10}>
                    <Breadcrumb data={metaInfo.breadcrumData} />
                </Grid>
                <Grid style={{ padding: 0 }} item xs={2}>
                    {userRole && <Box className={classes.masterBtn} display="flex" flexDirection="row" >
                        <Button style={{ marginRight: 12 }} component={Link} to={`${masterUrl}/add/category`}>
                            Add Category
                        </Button>
                        <Button component={Link} to={`${masterUrl}/add/question`}>
                            Add Q&A
                        </Button>
                    </Box>}
                </Grid>

                <Grid item xs={12} style={{ marginTop: 12 }}>
                    <div className={classes.contentGrid}>
                        <Accordion data={faqData} deleteFaqCategory={deleteFaqCategory} deleteFaqQuestion={deleteFaqQuestion} userRole={userRole}/>
                    </div>
                </Grid>
            </Grid>

        </>
    );
}

export default List;