/* Auth0 code Start */
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './App';
import store from "redux/store";
import BrowserCompatible from "components/browser/BrowserCompatible";
import { getBrowser, isSupported } from "utils/detectBrowser";
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import history from "router-history";
import { createRoot } from 'react-dom/client';
import { getConfig } from 'config';
import { Auth0Provider } from '@auth0/auth0-react';

const container = document.getElementById('root');
const root = createRoot(container);
const browser = getBrowser();
const supported = isSupported(browser);

const config = getConfig();

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  dev:config.dev,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};




root.render(
  (supported) ? (
    <Provider store={store}>
      <Auth0Provider
        {...providerConfig}
        useRefreshTokens={true}
      >
        <Router history={history}>
          <App />
        </Router>
      </Auth0Provider>
    </Provider>
  ) : <BrowserCompatible browserInfo={browser} />

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/* Auth0 code End */

/*Okta Code Start */
// import React from 'react';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import App from './App';
// import store from "redux/store";
// import BrowserCompatible from "components/browser/BrowserCompatible";
// import { getBrowser, isSupported } from "utils/detectBrowser";
// import { Provider } from 'react-redux';
// import { Router } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
// import history from "router-history";
// import { createRoot } from 'react-dom/client';

// const container = document.getElementById('root');
// const root = createRoot(container);
// const browser = getBrowser();
// const supported = isSupported(browser);

// root.render(
//   (supported) ? (
//     <Provider store={store}>
//       <Router history={history}>
//       <App />
//       </Router>
//     </Provider>
//   ) : <BrowserCompatible browserInfo={browser} />
  
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
/*Okta Code End */