import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
function configureStore(initialState = {}) {
  let middleware;
  if (process.env.NODE_ENV !== "production") {
    middleware = applyMiddleware(thunk, logger);
  } else {
    middleware = applyMiddleware(thunk);
  }
  return createStore(rootReducer, initialState, middleware);
}
const store = configureStore();
export default store;
