import React,{useState} from 'react';
import { HvDialog, HvDialogContent, HvDialogTitle } from '@hitachivantara/uikit-react-core';
import { useHistory } from 'react-router';

const UserGuide = () => {
    const [open, setOpen] = useState(true);
    const history = useHistory();
    const UserGuide = process.env.REACT_APP_UserGuide
    return (
        <div>
            <HvDialog open={open} fullScreen={open} onClose={() => {
                setOpen(!open);
                history.goBack();
            }}>
                <HvDialogTitle variant='info' style={{flex:"0 0 0%"}}>User Guide</HvDialogTitle>
                <HvDialogContent indentContent >
                    <object data={UserGuide} aria-label="Url" type="text/html" width={"100%"} height={window.screen.height} />
                </HvDialogContent>
            </HvDialog>
        </div>
    );
};

export default UserGuide;