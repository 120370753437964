/* Below code is AUth0 code */
import React, { useEffect, useRef, useState } from 'react';
import AppLayout from 'layout/AppLayout';
import Routes from 'routes/Routes';
import Spinner from 'components/spinner/Spinner';
import Banner from "components/banner/Banner";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import LoginService from 'services/LoginService';
import { useStyles } from 'utils/theme';
import { withRouter, useLocation } from 'react-router';
import { useSelector } from "react-redux";
import { HvProvider } from "@hitachivantara/uikit-react-core";
import { config } from "config";
import { OktaAuth } from "@okta/okta-auth-js";
import { setTokenExpiry } from "redux/actions/loginActions";
import { doSsoLogin } from 'redux/actions/loginActions';
import { showBanner } from 'redux/actions/bannerActions';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { getAndSetTokenExpiry } from 'utils/common';
import _ from 'lodash';
import Unauthorized from 'components/Unauthorized';
import InactivityMonitor from 'utils/InactivityMonitor';
import { LOGGED_IN_USER_INFO, IDEL_TIMEOUT_MINUTS, IDEL_GRACE_TIME_SECONDS } from "constants/common";
const oktaAuth = new OktaAuth(config.oidc);
const loginServiceObj = new LoginService();
let intervalId;

function App({ history }) {
  let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
  let token = data ? JSON.parse(data) : {}
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.themeReducer.theme);

  const location = useLocation();
  const isScPortal = location.pathname === '/scportal';


  const [renderAppRoutes, setRenderAppRoutes] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const ref = useRef();
  const expiryTime = useSelector((state) => state.loginReducer.expiryTime);
  const prevLabUrl = localStorage.getItem("LabUrl");
  ref.current = expiryTime;
  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims, logout } = useAuth0();

  const getToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    setRenderAppRoutes(true);
    if (isAuthenticated) {
      let browserURL = window.location.href;
      if (browserURL.search("labId") > 0) {
        history.push(browserURL.split(window.location.origin)[1])
      }
    } else {
      if (!isAuthenticated) {
        let browserURL = window.location.href;
        if (browserURL.search("labId") > 0) {
          localStorage.setItem("LabUrl", browserURL.split(window.location.origin)[1])
          history.push("/");
        }
      } else {
        let browserURL = window.location.href;
        if (browserURL.search("labId") > 0) {
          getToken().then((accesstoken) => {
            getIdTokenClaims().then((token) => {
              const tokenExpirationTime = getAndSetTokenExpiry(token);
              dispatch(setTokenExpiry(tokenExpirationTime));
              ref.current = tokenExpirationTime;
              const payload = {
                encrypted_token: accesstoken,
                id_token: token.__raw
              }
              loginServiceObj.verifySsoToken(payload).then(result => {
                if (result.id && result.access_token) {
                  result['type'] = 'sso';
                  dispatch(doSsoLogin(result));
                  history.push(browserURL.split(window.location.origin)[1])
                }
              }).catch(() => {
                dispatch(showBanner({
                  payload: {
                    showBanner: true,
                    label: { message: "Error Occured while SSO Login. Please try again." },
                    variant: "error"
                  }
                }))
                history.push("/");
              });
            })
          });

        } else {
          history.push("/labs");
        }

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener('resize', handleResize);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAuthenticated, prevLabUrl])

  const isTokenAboutToExpire = () => {
    const currentTime = Date.now() / 1000; // Convert to seconds
    console.log("token expiry", ref.current - currentTime)
    return ref.current - currentTime <= 60 // Check if the token is about to expire (e.g., 1 minute before expiry)
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = await getAccessTokenSilently()
      getIdTokenClaims().then((token) => {
        if (token) {
          const tokenExpirationTime = getAndSetTokenExpiry(token);
          dispatch(setTokenExpiry(tokenExpirationTime));
          ref.current = tokenExpirationTime;
          const payload = { encrypted_token: refreshToken, id_token: token.__raw };
          loginServiceObj.verifySsoToken(payload).then(result => {
            if (result.id && result.access_token) {
              result['type'] = 'sso';
              dispatch(doSsoLogin(result));
            }
          }).catch(() => {
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: "Error Occured while SSO Login. Please try again." },
                variant: "error"
              }
            }))
            history.push("/");
            logoutWithRedirect();

          });
        }
      })
    } catch (error) {
      console.error('Token refresh error:', error);
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error Occured while renewing token, Please login again." },
          variant: "error"
        }
      }))
      clearInterval(intervalId);
      history.push("/");
    }
  };
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: "/",
      }
    }).catch(() => {
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error while logout through SSO, Please login again." },
          variant: "error"
        }
      }))
    })
  // Check for token expiration every minute
  const tokenRefreshInterval = () => {
    if (isAuthenticated) {
      if (isTokenAboutToExpire()) {
        refreshAccessToken();
      }
    }
  }

  useEffect(() => {
    intervalId = setInterval(tokenRefreshInterval, 60000);
    return () => clearInterval(intervalId);
  }, [isAuthenticated, ref]);

  return (

    <HvProvider uiKitTheme={theme}  cssBaseline='global'>
      {
        windowSize.width >= 768 ?
          <div className={classes.appContainer}>
            {/* {
              (isAuthenticated || token?.access_token && !isScPortal) && < InactivityMonitor timeout={IDEL_TIMEOUT_MINUTS * 60 * 1000} graceTime={IDEL_GRACE_TIME_SECONDS} />
            } */}
            <head><link
              href="https://fonts.googleapis.com/css?family=Open+Sans:400,600"
              rel="stylesheet"
            /></head>
            <Spinner />

            <Banner />

            <ToastContainer
              position="top-right"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable={false}
              pauseOnHover
            />
            <AppLayout>
              {
                renderAppRoutes && <Routes />
              }
            </AppLayout>
          </div> : <Unauthorized />


      }


    </HvProvider>

  );

}
export default withRouter(App);
/* Auth0 Code ends here */

/* okta Implementation  Start*/
// import React, { useEffect, useState } from 'react';
// import AppLayout from 'layout/AppLayout';
// import Routes from 'routes/Routes';
// import Spinner from 'components/spinner/Spinner';
// import Banner from "components/banner/Banner";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useDispatch } from 'react-redux';
// import LoginService from 'services/LoginService';
// import { useStyles } from 'utils/theme';
// import { withRouter } from 'react-router';
// import { useSelector } from "react-redux";
// import { HvProvider } from "@hitachivantara/uikit-react-core";
// import { config } from "config";
// import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import { Security } from '@okta/okta-react';
// import { LOGGED_IN_USER_INFO, OKTA_TOKEN_INFO } from 'constants/common';
// import { doSsoLogin } from 'redux/actions/loginActions';
// import { showBanner } from 'redux/actions/bannerActions';
// import Unauthorized from 'components/Unauthorized';

// import _ from 'lodash';
// const oktaAuth = new OktaAuth(config.oidc);

// const loginServiceObj = new LoginService();

// function App({ history }) {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const theme = useSelector((state) => state.themeReducer.theme);
//   const [renderAppRoutes, setRenderAppRoutes] = useState(false);
//   const [authenticatedOkta, setAuthenticateOkta] = useState(false);
//   const prevLabUrl = localStorage.getItem("LabUrl");
//   const token = JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO));
//   const [windowSize, setWindowSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight
//   });


//   useEffect(() => {
//     const handleResize = () => {
//       setWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight
//       });
//     };

//     setRenderAppRoutes(true);
//     if (authenticatedOkta || token?.access_token) {
//       let browserURL = window.location.href;
//       if (browserURL.search("labId") > 0) {
//         history.push(browserURL.split(window.location.origin)[1])
//       }
//     } else {
//       const data = localStorage.getItem(OKTA_TOKEN_INFO);
//       const token = data ? JSON.parse(data) : {};
//       if (!token?.accessToken && !token?.accessToken?.accessToken) {
//         let browserURL = window.location.href;
//         if (browserURL.search("labId") > 0) {
//           localStorage.setItem("LabUrl", browserURL.split(window.location.origin)[1])
//           history.push("/");
//         }
//       } else {
//         let browserURL = window.location.href;
//         if (browserURL.search("labId") > 0) {
//           const payload = { encrypted_token: token?.accessToken.accessToken };
//           loginServiceObj.verifySsoToken(payload).then(result => {
//             if (result.id && result.access_token) {
//               result['type'] = 'sso';
//               dispatch(doSsoLogin(result));
//               history.push(browserURL.split(window.location.origin)[1])
//             }
//           }).catch(() => {
//             dispatch(showBanner({
//               payload: {
//                 showBanner: true,
//                 label: { message: "Error Occured while SSO Login. Please try again." },
//                 variant: "error"
//               }
//             }))
//             history.push("/");
//           });

//         } else {
//           history.push("/labs");
//         }

//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     window.addEventListener('resize', handleResize);
//     // Clean up event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };

//   }, [token?.access_token, authenticatedOkta, oktaAuth.authStateManager.getAuthState()?.isAuthenticated, prevLabUrl])

//   const refreshAccessToken = async () => {
//     try {
//       await oktaAuth.token.renewTokens().then(renewToken => {
//         oktaAuth.tokenManager.setTokens(renewToken);
//       }).then(() => {
//         const data = localStorage.getItem(OKTA_TOKEN_INFO);
//         const token = data ? JSON.parse(data) : {};
//         if (token) {
//           const payload = { encrypted_token: token?.accessToken.accessToken };
//           loginServiceObj.verifySsoToken(payload).then(result => {
//             if (result.id && result.access_token) {
//               result['type'] = 'sso';
//               dispatch(doSsoLogin(result));
//             }
//           }).catch(() => {
//             dispatch(showBanner({
//               payload: {
//                 showBanner: true,
//                 label: { message: "Error Occured while SSO Login. Please try again." },
//                 variant: "error"
//               }
//             }))
//             history.push("/");
//           });
//         }
//       })
//     } catch (error) {
//       console.error('Token refresh error:', error);
//       dispatch(showBanner({
//         payload: {
//           showBanner: true,
//           label: { message: "Error Occured while renewing token, Please login again." },
//           variant: "error"
//         }
//       }))
//       history.push("/");
//     }
//   };
//   const isTokenAboutToExpire = () => {
//     const data = localStorage.getItem(OKTA_TOKEN_INFO);
//     const token = data ? JSON.parse(data) : {};
//     // You can determine this by comparing the token's expiration time with the current time
//     // Here, we assume the token has an "expiresAt" timestamp
//     if (!token.accessToken || !token.accessToken.expiresAt) {
//       return false;
//     }
//     const currentTime = Date.now() / 1000; // Convert to seconds
//     const tokenExpiry = token.accessToken.expiresAt;

//     // Refresh token 1 minute before expiry (60 seconds)
//     return tokenExpiry - currentTime <= 60;
//   };

//   useEffect(() => {
//     // Check for token expiration every minute
//     const tokenRefreshInterval = setInterval(() => {
//       if (oktaAuth.authStateManager.getAuthState()?.isAuthenticated) {
//         if (isTokenAboutToExpire()) {
//           refreshAccessToken();
//         }
//       } else {
//         clearInterval(tokenRefreshInterval);
//       }
//     }, 60000); // 60,000 milliseconds = 1 minute
//   }, []);
//   const triggerLogin = async () => {
//     await oktaAuth.signInWithRedirect();
//   };

//   const restoreOriginalUri = async (_oktaAuth, originalUri) => {
//     history.push(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
//   };

//   const customAuthHandler = async () => {
//     const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
//     if (!previousAuthState || !previousAuthState.isAuthenticated) {
//       // App initialization stage
//       await triggerLogin();
//     } else {
//       // Ask the user to trigger the login process during token autoRenew process
//     }
//   };
//   return (
//     <HvProvider uiKitTheme={theme}>
//       <Security
//         oktaAuth={oktaAuth}
//         onAuthRequired={customAuthHandler}
//         restoreOriginalUri={restoreOriginalUri}
//       >
//         <div className={classes.appContainer}>
//           <head><link
//             href="https://fonts.googleapis.com/css?family=Open+Sans:400,600"
//             rel="stylesheet"
//           /></head>
//           <Spinner />
//           <Banner />
//           <ToastContainer
//             position="top-right"
//             autoClose={false}
//             hideProgressBar={false}
//             newestOnTop
//             closeOnClick
//             rtl={false}
//             pauseOnVisibilityChange
//             draggable={false}
//             pauseOnHover
//           />
//           <AppLayout>

//             {
//               windowSize.width >= 768 ?
//                 renderAppRoutes && <Routes />
//                 : <Unauthorized />
//             }


//           </AppLayout>
//         </div>
//       </Security>

//     </HvProvider>

//   );
// }
// export default withRouter(App);
/* okta Implementation  End*/