import React, { useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { pageSizeOptions } from 'constants/common.js';
import uuid from 'react-uuid'; 
import { useStyles } from './style.js';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '4px 12px 4px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      background: '#fff',
    },
  },
}))(InputBase);

export default function PaginationButtons(props) {
  const classes = useStyles();
  const { handlePageSize, totalCount, pageSize, handlePageVal, pageNumber, selectedTabIndex } = props;
  const [page, setPage] = React.useState(10);

  useEffect(()=>{
    handlePageVal(1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTabIndex])
  
  const handleChange = (event) => {
    const value = event.target.value;
    handlePageVal(1);
    handlePageSize(value);
    setPage(value);
  };

  const handlePagination = (e, pageVal) => {
    handlePageVal(pageVal)
  }

  return (
    <>
      <div className={classes.root}>
        <Pagination
          count={Math.ceil(totalCount / pageSize)}
          showFirstButton
          showLastButton
          shape='rounded'
          onChange={handlePagination}
          page={pageNumber}
        />
      </div>
      <div className={classes.pagesizeDropdown}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={page}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {pageSizeOptions && pageSizeOptions.length > 0 && pageSizeOptions.map(page => {
            return (
              <MenuItem key={uuid()} className={classes.item} value={page}>{page} / page</MenuItem>
            );
          })}

        </Select>
      </div>
    </>
  );
}
