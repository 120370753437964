import { HvDonutChart } from "@hitachivantara/uikit-react-viz";
import { HvBarChart } from "@hitachivantara/uikit-react-viz";
import { REPORT_SIZE_OPTIONS } from "constants/labs";
import {
  HvContainer,
  HvSection,
  HvDropDownMenu,
} from "@hitachivantara/uikit-react-core";
import {
  HvDatePicker,
  HvGrid,
  HvTypography,
  HvFilterGroup,
  HvPanel,
  HvEmptyState,
} from "@hitachivantara/uikit-react-core";
import { Info } from "@hitachivantara/uikit-react-icons";
import ReportService from "services/ReportService.js";
import { useStyles } from "./styles";
import React, { useEffect, useState } from "react";
import { buildQueryParams } from "utils/common.js";
import { useTheme } from "@material-ui/core/styles";
import TableView from "./TableView";
import moment from "moment";
import _ from "lodash";

const reportsServiceObj = new ReportService();
function Charts() {
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const [exportType, setExportType] = useState();
  const [displayExport, setDisplayExport] = useState(0);
  const [pageLimit, setPageLimit] = useState(REPORT_SIZE_OPTIONS[0]);
  const [pageOffset, setPageOffset] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState(["Data Resilience and Compliance", "Universal Data and Application Management", "Hybrid Cloud Solutions"]);
  const [selectedUserType, setSelectedUserType] = useState(["Employees", "Partners"]);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(new Date());
  const [barUserType, setBarUserType] = useState();
  const [barChartData, setBarChartData] = useState([]);
  const [dounetChartData, setDounetChartData] = useState([]);
  const [customStartDate, setCustomStartDate] = useState();
  const [customEndDate, setCustomEndDate] = useState();
  const categoryArray = [
    {
      id: "Data Resilience and Compliance",
      name: "Data Resilience and Compliance",
    },
    {
      id: "Universal Data and Application Management",
      name: "Universal Data and Application Management",
    },
    {
      id: "Hybrid Cloud Solutions",
      name: "Hybrid Cloud Solutions",
    },
  ];
  const defaultCategory = [
    "Data Resilience and Compliance",
    "Universal Data and Application Management",
    "Hybrid Cloud Solutions",
  ];
  const UserType = [
    { id: "Employees", name: "Employees", value: "Employees" },
    { id: "Partners", name: "Partners", value: "Partners" },
  ];

  const defaultUserType = ["Employees", "Partners"]
  const [filters, setFilters] = useState([
    {
      id: "1",
      name: "Category",
      data: categoryArray,
    },
    {
      id: "2",
      name: "User Type",
      data: UserType,
    },
  ]);
  const [selectedFilterValue, setSelectedFilterValue] = useState();

  const classes = useStyles();
  const theme = useTheme();

  // Get the current date
  const currentDate = new Date();

  // Subtract 30 days

  const labels = {
    applyLabel: "Apply",
    cancelLabel: "Cancel",
  };

  const getReportByDateRange = (values, startDate, endDate) => {
    let params = {};
    let donutParams = {};
    let chartData = { Group: [], Employees: [], Partners: [] };
    let dougnetChartData = { Sessions: [], User: [] };
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");
    params["lab_categories"] = selectedCategories.join(",");
    params["user_group_names"] = selectedUserType.join(",");
    reportsServiceObj
      .getAllEmployeesPartnersBarChart(buildQueryParams(params))
      .then((response) => {
        if (response) {
          if (response?.length > 0) {
            defaultUserType.forEach(key => {
              const match = response.find(item => item.name === key);
              if (match) {
                chartData[key] = match.x;
                if (chartData.Group.length === 0) {
                  chartData.Group = match.y;
                }
              }
            });

            if (!chartData.Employees.length) {
              chartData.Employees = Array(chartData.Group.length).fill(0);
            }
            if (!chartData.Partners.length) {
              chartData.Partners = Array(chartData.Group.length).fill(0);
            }
            const modifiedData = [];
            modifiedData.push(chartData)
            setBarChartData(modifiedData)
          }else{
            setBarChartData([]);
          }
        }
      });

    donutParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    donutParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    donutParams["lab_categories"] = selectedCategories.join(",")
    donutParams["user_group_names"] = selectedUserType.join(",")
    reportsServiceObj
      .getAllEmployeesPartnersSessionsDounetChart(buildQueryParams(donutParams))
      .then((response) => {
        if (response) {
          dougnetChartData.Sessions = response?.values;
          dougnetChartData.User = response?.labels
          let modifiedDounetChartData = [];
          modifiedDounetChartData.push(dougnetChartData);
          setDounetChartData(modifiedDounetChartData);
        }else{
          setDounetChartData([]);
        }
      });
    setDisplayExport(1);
  };

  //export data to excel
  const getExcelLabReport = () => {
    let params = {};
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");
    reportsServiceObj
      .getExcelLabReport(buildQueryParams(params))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.xlsx"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Excel data:", error);
      });
  };

  //export data to excel
  const getPDFLabReport = () => {
    let params = {};
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");
    reportsServiceObj
      .getPDFLabReport(buildQueryParams(params))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.pdf"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF data:", error);
      });
  };

  useEffect(() => {

    getReportByDateRange([], startDate, endDate);

  }, [pageLimit, pageOffset, startDate, endDate, selectedCategories, selectedUserType]);

  return (
    <>
      <HvContainer>
        <HvPanel>
          <HvTypography variant="xsTitle">
            Labs Details Report - By Lab Category and User Group
          </HvTypography>
        </HvPanel>
        <HvGrid
          container
          spacing={2}
          rowSpacing={5}
          style={{ paddingTop: "50px", paddingLeft: "25px" }}
        >
          <HvGrid
            item
            xs={1}
            sm={1}
            md={2}
            lg={1}
            xl={2}
            style={{ display: "contents", paddingLeft: "15px" }}
          >
            <HvTypography
              variant="highlightText"
              style={{ alignSelf: "center" }}
            >
              Select Filters:
            </HvTypography>
          </HvGrid>
          <HvGrid
            item
            tem
            xs={1}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            style={{ paddingTop: "5px" }}
          >
            <HvFilterGroup
              aria-label="Main filter group"
              id="reportFilter"
              filters={filters}
              value={selectedFilterValue}
              onChange={(_, values) => {
                setSelectedCategories(values[0]?.length > 0 ? values[0] : defaultCategory)
                setSelectedUserType(values[1]?.length > 0 ? values[1] : defaultUserType)
              }

              }
              onClear={() => {
                setBarChartData([]);
                setDounetChartData([]);
                setSelectedFilterValue([]);
              }}
              onCancel={() => {
                setSelectedFilterValue([]);
              }}
              escapeWithReference={true}
              height={"350px"}
              className={classes.filterUserGroup}
            />
          </HvGrid>
          <HvGrid
            item
            tem
            xs={1}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            style={{ paddingTop: "5px", position: "relative", zIndex: 1 }}
          >
            <HvDatePicker
              id="DatePicker"
              aria-label="Date"
              placeholder="Select a range"
              labels={labels}
              rangeMode
              showClear
              calendarProps={{
                maximumDate: new Date(),
              }}
              startValue={customStartDate ? customStartDate : startDate}
              endValue={customEndDate ? customEndDate : endDate}
              onChange={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
                
              }}
              onClear={() => {
                setBarChartData([]);
                setDounetChartData([]);
              }}
            />
          </HvGrid>
          {barChartData?.length > 0 ? (
            <HvGrid
              item
              xs={1}
              sm={1}
              md={2}
              lg={1}
              xl={2}
              style={{ position: "absolute", top: 125, right: 100 }}
            >
              <HvDropDownMenu
                dataList={[
                  {
                    label: "Download Excel Report",
                  },
                  {
                    label: "Download PDF Report",
                  },
                ]}
                //defaultExpanded
                placement="left"
                size="md"
                variant="secondaryGhost"
                onClick={(e, value) =>
                  value.label === "Download Excel Report"
                    ? getExcelLabReport()
                    : getPDFLabReport()
                }
              />
            </HvGrid>
          ) : (
            ""
          )}
        </HvGrid>
        {barChartData?.length > 0 ? (
          <>
            <HvGrid container style={{ paddingTop: "30px" }}>
              <HvGrid
                item
                xs={4}
                sm={6}
                md={4}
                lg={8}
                xl={8}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"bar-0"}
                  title={
                    <>
                      <HvGrid container>
                        <HvGrid item xs={4} sm={4} md={4} lg={6} xl={4}>
                          {
                            selectedUserType?.length > 1 || selectedUserType < 1 ? (
                              <HvTypography variant="label">
                                Lab Usage by Employees and Partners
                              </HvTypography>
                            ) : selectedUserType[0] === "Employees" ? (
                              <HvTypography variant="label">
                                Lab Usage by Employees
                              </HvTypography>
                            ) : (
                              <HvTypography variant="label">
                                Lab Usage by Partners
                              </HvTypography>
                            )
                          }
                        </HvGrid>
                      </HvGrid>
                    </>
                  }
                  style={{ position: "relative" }}
                >

                  <HvBarChart
                    key={"bar-0"}
                    data={barChartData[0]}
                    groupBy="Group"
                    horizontal
                    measures={selectedUserType}
                    stack="total"
                    title="Lab Usage by Employees and Partners"
                    height={400}
                    grid={{ left: 200 }}
                    yAxis={{ type: "categorical", position: "left" }}
                    onOptionChange={(option) => {
                      if (Array.isArray(option.yAxis) &&
                        option.yAxis.length === 1) {
                        option.yAxis = [
                          {
                            ...option.yAxis[0],
                            axisLabel: {
                              ...option.yAxis[0].axisLabel,
                              width: 100,
                              overflow: "truncate",
                              fontSize: 10
                            },
                          },
                          option.legend.show = true
                        ];
                      }
                      option.grid = [{
                        left: '3%',
                        right: '4%',
                        bottom: barChartData[0]?.Group?.length > 10 ? '2%' : '3%', // Increase bottom margin if slider is present
                        containLabel: true
                      }]
                      option.dataZoom = barChartData[0]?.Group?.length > 10 ? [
                        {
                          type: 'slider',
                          yAxisIndex: 0,
                          start: 0,
                          end: barChartData[0]?.Group?.length > 10 ? 10 : 100, // Show the first 10 items initially
                          labelFormatter: '' // Set to empty to remove the labels
                        }
                      ] : []
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
              <HvGrid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"donut-2"}
                  classes={{
                    content: "css-10klw3m",
                  }}
                  title={
                    selectedUserType?.length > 1 || selectedUserType < 1 ? (
                      <HvTypography variant="label">
                        Number of Sessions by Employees and Partners
                      </HvTypography>
                    ) : selectedUserType[0] === "Employees" ? (
                      <HvTypography variant="label">
                        Number of Sessions by Employees
                      </HvTypography>
                    ) : (
                      <HvTypography variant="label">
                        Number of Sessions by Partners
                      </HvTypography>
                    )
                  }
                  style={{ position: "relative" }}
                >
                  {
                    dounetChartData.length > 0 ? <HvDonutChart
                      key={"donut-2"}
                      data={dounetChartData[0]}
                      groupBy="User"
                      measure="Sessions"
                      type="regular"
                      title="Lab Use by Employees and Partners"
                      subtitle="Number of HALO labs used by each group"
                      height={400}
                      onOptionChange={(option) => {
                        option.tooltip = {
                          confine: true,
                        }
                        return option;
                      }}
                    /> : <></>
                  }

                </HvSection>
              </HvGrid>
            </HvGrid>
            <HvGrid container style={{ paddingTop: "50px" }} columnGap={10}>
              <HvGrid item xs={4} sm={8} md={8} lg={12} xl={12}>
                  <TableView
                    startDate={moment(startDate).format("YYYY-MM-DD")}
                    endDate={moment(endDate).format("YYYY-MM-DD")}
                    categories={selectedCategories?.join(",")}
                    userType={selectedUserType.join(",")}
                  />
              </HvGrid>
            </HvGrid>
          </>
        ) : (
          <HvEmptyState
            icon={<Info />}
            message="No data to display."
          />
        )}
      </HvContainer>
    </>
  );
}
export default Charts;
