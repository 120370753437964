import NetworkService from "./NetworkService";
import {
    METHOD_ADMIN_SETINGS,
    METHOD_ENCRYPT_PASSWORD
} from "constants/common";

class CommonService {
    getAdminSettings = () => {
        return NetworkService.get(METHOD_ADMIN_SETINGS).then((response) => response.data);
    };
    saveAdminSettings = (payload) => {
        return NetworkService.patch(METHOD_ADMIN_SETINGS, payload).then((response) => response.data);
    };
    encryptPlainText = (payload) =>{   
        return NetworkService.post(METHOD_ENCRYPT_PASSWORD,payload).then((response)=>response.data);
    }
}
export default CommonService;