//API Method Constants
//for backdoor login
export const METHOD_BACKDOOR_LOGIN = 'backenduser/';
export const METHOD_lOGIN ='login/'
export const METHOD_USERINFO = 'users/me/';
export const METHOD_REGISTER = 'user/'
export const METHOD_FORGOT_PASSWORD = 'user/forgot-password/';
export const METHOD_RESET_PASSWORD = 'user/reset-password/';
export const METHOD_EMAIL_AUTHENTICATE = 'user/verify/'
export const LANGUAGE_LOGIN_ERROR_LAB = 'Login to Run LAB';

//reducer and action keys
export const USER_INFO='USER_INFO';
export const LAB_INFO = 'LAB_INFO';
export const TOKEN_EXPIRE_TIME = "TOKEN_EXPIRE_TIME";

//for sso login
export const METHOD_SSO_REDIRECT_URL = 'signin/';
export const METHOD_SSO_VERIFY_TOKEN = 'authenticate/';
export const METHOD_SSO_LOGOUT = 'signout/';
//language for sso
export const LANGUAGE_BAD_SSO_URL = 'Invalid Sso url.';
export const LANGUAGE_GENERAL_SSO_ERROR = 'Some error occured.';
