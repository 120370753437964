export const METHOD_TAGS_LIST = 'tag/';
export const METHOD_GROUP_WITH_USERS_LIST = 'user-group/';
export const LAB_USER_PERMISSION = 'lab-user-permission/';
export const LAB_GROUP_PERMISSION = 'lab-group-permission/';

export const tabs = [
    "General", "Consoles", "Permissions", "Instructions", "Tags" ,"Resources","CleanUp"
];

export const labStatus = [
    { label: 'Active', value: 'Active' },
    { label: 'In development', value: 'In Development' },
    { label: 'Retired', value: 'Retired' }
];

export const labType = [
    { label: 'Run Lab', value: 'Run Lab' },
    { label: 'Schedule Lab', value: 'Schedule Lab' }
]
export const isPublic = [
    { id: 0, name: 'Is Public' },
   
]

export const isAutoSuspend = [
    { id: 0, name: 'Auto Suspend' },
   
]
export const labCategory = [{ id: 1, name: 'Billable' }, { id: 2, name: 'Showcase Lab' }];


export const labAuthors = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder"
];


export const consoleList = [
    {
        name: 'Dhruv',
        description: '172.23.98.16',
        type: 'RDP',
        order: '0',
    },
    {
        name: 'Jeetendra',
        description: '172.28.8.116',
        type: 'RDP',
        order: '0',
    },
    {
        name: 'Ganesh',
        description: '12.213.28.1',
        type: 'RDP',
        order: '0',
    },
];

export const consoleButtons = [
    'Add Template',
]

export const timers = [
    { label: 30, value: 30 },
    { label: 60, value: 60 },
    { label: 90, value: 90 },
    { label: 120, value: 120 },
    { label: 150, value: 150 },
    { label: 180, value: 180 },
    { label: 210, value: 210 },
    { label: 240, value: 240 },
    { label: 270, value: 270 },
    { label: 300, value: 300 },
]


