import ROLES from "./roles";


export const profileMenu = [
  {
    title: "Labs",
    icon: "/assets/images/labs.png",
    requiredRoles: [ROLES.System_User],
    // requiredGroups: [SYSTEM_GROUPS.Employees],
    children: [
      {
        title: "Labs",
        icon: "/assets/images/labs.png",
        parentTitle: "Labs",
        url: "/admin/masters/labs",
        requiredRoles: [ROLES.System_User],
        // requiredGroups: [SYSTEM_GROUPS.Employees],
      },
      {
        title: "Categories",
        icon: "/assets/images/list.png",
        parentTitle: "Labs",
        url: "/admin/masters/lab/category",
        requiredRoles: [ROLES.System_User],
        // requiredGroups: [SYSTEM_GROUPS.Employees],
      },
    ],
  },

 

  {
    title: "Users",
    requiredRoles: [ROLES.System_User],
    url: "/admin/masters/users",
    icon: "/assets/images/user-list.png",
  },

  {
    title: "Admin Settings",
    icon: "/assets/images/admin.png",
    requiredRoles: [ROLES.System_User],
    // requiredGroups: [SYSTEM_GROUPS.Employees],
    children: [
      {
        title: "Help",
        icon: "/assets/images/question.png",
        parentTitle: "Admin Settings",
        requiredRoles: [ROLES.System_User],
        // requiredGroups: [SYSTEM_GROUPS.Employees],
        children: [
          {
            title: "FAQ",
            icon: "/assets/images/faq.png",
            parentTitle: "Help",
            url: "/admin/masters/faq",
            requiredRoles: [ROLES.System_User],
            // requiredGroups: [SYSTEM_GROUPS.Employees],
          },
          {
            title: "Feedback",
            icon: "/assets/images/rating.png",
            parentTitle: "Help",
            url: "/admin/masters/feedback",
            requiredRoles: [ROLES.System_User],
            // requiredGroups: [SYSTEM_GROUPS.Employees],
          },
          {
            title: "Support",
            icon: "/assets/images/support.png",
            url: "/admin/masters/support",
            parentTitle: "Help",
            requiredRoles: [ROLES.System_User],
            // requiredGroups: [SYSTEM_GROUPS.Employees],
          },
        ],
      },

      
      {
        title: "App Settings",
        icon: "/assets/images/setting.png",
        parentTitle: "Admin Settings",
        url: "/admin/settings",
        requiredRoles: [ROLES.System_User],
      },
    ],
  },

  {
    title: "Reports",
    icon: "/assets/images/admin.png",
    //url: "/admin/masters/reports",
    requiredRoles: [ROLES.System_User],
    // requiredGroups: [SYSTEM_GROUPS.Employees],
    children: [
      {
        title: "User Groups",
        icon: "/assets/images/group.png",
        parentTitle: "Reports",
        url: "/admin/masters/userGroups",
        requiredRoles: [ROLES.System_User],
        // requiredGroups: [SYSTEM_GROUPS.Employees],
      },
      {
        title: "Top 10",
        icon: "/assets/images/user-list.png",
        parentTitle: "Reports",
        url: "/admin/masters/top10",
        requiredRoles: [ROLES.System_User],
        // requiredGroups: [SYSTEM_GROUPS.Employees],
      },
      {
        title: "Geography",
        icon: "/assets/images/user-list.png",
        parentTitle: "Reports",
        url: "/admin/masters/geography",
        requiredRoles: [ROLES.System_User],
        // requiredGroups: [SYSTEM_GROUPS.Employees],
      },
    ],
  },
  {
    title: 'User Guide',
    icon: '/assets/images/OpenBook.png',
    url: '/UserGuide',
  },
  {
    title: 'Log Off',
    icon: '/assets/images/Logout.png',
    url: '/logout',
  }
];