import NetworkService from "services/NetworkService";
import { METHOD_TAGS_LIST, METHOD_GROUP_WITH_USERS_LIST, LAB_USER_PERMISSION, LAB_GROUP_PERMISSION } from "constants/admin/masters/labs";

class LabMasterService {
  getAllTagsList = () => {
    return NetworkService
      .get(METHOD_TAGS_LIST)
      .then((response) => response.data);
  };
  getAllGroupsWithUsers = () => {
    return NetworkService
      .get(METHOD_GROUP_WITH_USERS_LIST)
      .then((response) => response.data);
  };
  createIdForDynamicTag = (payload) => {
    return NetworkService
      .post(METHOD_TAGS_LIST, payload)
      .then((response) => response.data);
  }
  createLabUserPermission = (payload) => {
    return NetworkService
      .post(LAB_USER_PERMISSION, payload)
      .then((response) => response.data);
  }
  updateLabUserPermission = (payload, permissionId) => {
      return NetworkService
      .patch(`${LAB_USER_PERMISSION}${permissionId}/`, payload)
      .then((response) => response.data);
  }
  createLabGroupPermission = (payload) => {
    return NetworkService
      .post(LAB_GROUP_PERMISSION, payload)
      .then((response) => response.data);
  }
  updateLabGroupPermission = (payload, permissionId) => {
      return NetworkService
      .patch(`${LAB_GROUP_PERMISSION}${permissionId}/`, payload)
      .then((response) => response.data);
  }
  deleteLabGroupPermission = (permissionId) =>{
    return NetworkService
      .delete(`${LAB_GROUP_PERMISSION}${permissionId}/`)
      .then((response) => response.data);
  }

  deleteLabUserPermission = (permissionId) =>{
    return NetworkService
      .delete(`${LAB_USER_PERMISSION}${permissionId}/`)
      .then((response) => response.data);
  }
  
  getPermissionUserConfiguration = (configId) => {
    return NetworkService
      .get(`${LAB_USER_PERMISSION}${configId}/`)
      .then((response) => response.data);
  }
  getPermissionGroupConfiguration = (configId) => {
    return NetworkService
      .get(`${LAB_GROUP_PERMISSION}${configId}/`)
      .then((response) => response.data);
  }
}
export default LabMasterService;