import { theme } from "@hitachivantara/uikit-react-core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    globalAction: {
        '& .HvGlobalActions-wrapper': {
            justifyContent: "center"
        },
        '& .HvGlobalActions-actions': {
            marginLeft: "100px"
        },
        '& .HvGlobalActions-global': {
            zIndex: 0
        }
    },
    readOrHide: {
        color: '#1874CD',
        cursor: 'pointer',
        textDecoration: 'underline',
    }
})
export default useStyles;
