import React, { useState,useEffect } from 'react';
import { HvSwitch } from "@hitachivantara/uikit-react-core";
import { makeStyles } from "@mui/styles";


function ThemeSwtich(props) {

    const useStyles = makeStyles((theme) => ({
        controlContainer: {
            width: "100%",
            maxWidth: 400,
        },

        labelContainer: {
            display: "flex",
            alignItems: "flex-start",
        },
        label: {
            paddingBottom: "6px",
        },

        switchContainer: {
            width: "100%",
            display: "flex",
            alignItems: "center",

            "& > *": {
                marginLeft: theme?.hv?.spacing.xs,
            },
            "& > *:first-child": {
                marginLeft: 0,
            },
           
        },
        lightStyle: {
            textAlign: "right",
            font: "normal normal normal 14px / 20px Open Sans",
            letterSpacing: "0px",
            color: "#414141",
            opacity: "1",
            padding: "8px"
        },
        darkStyle : {
            textAlign: "left",
            font: "normal normal normal 14px / 20px Open Sans",
            letterSpacing: "0px",
            color: "#414141",
            opacity: "1",
            padding: "8px"
        }
      }));

const classes = useStyles();

const [deactivatedSwitch] = useState(false);
const [state, setState] = useState(false);

const SwitchLabel = ({ label, labelStyle }) => {
    const variant = deactivatedSwitch ? "placeholderText" : "normalText";
    const clickCallback = deactivatedSwitch ? undefined : () => setState(!state);
    const style = deactivatedSwitch ? undefined : { cursor: "pointer" };
    return (
        <label className={labelStyle==="lightStyle"? classes.lightStyle: classes.darkStyle} aria-hidden="true" variant={variant} style={style} onClick={clickCallback}>
            {label}
        </label>
    );
};

const changeTheme = (_evt, newChecked)=>{ 
   _evt?.preventDefault();
   props.changeTheme(_evt,newChecked);
}

useEffect(() => {
 setState(props.state);
}, [props.state])


return (
    <div className={classes.controlContainer}>
        <div className={classes.switchContainer}>
            <SwitchLabel labelStyle="lightStyle" label="Light" />
            <HvSwitch
                id="engine-control"
                checked={state}
                aria-label="Engine Control"
                onChange={changeTheme}
            />
            <SwitchLabel labelStyle="darkStyle" label="Dark" />
        </div>
    </div>
);

}


export default ThemeSwtich;