import {
  Method_Download_Reports_Data,
  Method_Download_Reports_Data_PDF,
  Method_Download_Top10RunTime_Reports_Data,
  Method_Download_Top10RunTime_Reports_Data_PDF,
  Method_Download_Top10Session_Reports_Data,
  Method_Download_Top10Session_Reports_Data_PDF,
  Method_Geography,
  Method_Geography_Donut,
  Method_Geography_Table,
  Method_Get_LabUsage_Sessions_DougnetChart,
  Method_Get_LabUsage_UserType_BarChart,
  Method_Get_Session_Reports_List,
  Method_Get_Usage_Reports_List,
  Method_Get_User_Type_Report,
  Method_Top10_Instances,
  Method_Top10_Run_Time,
  Method_Top10_Table,
  Method_Get_All_Country,
  Method_Get_Table_Country,
  Method_Get_Geography_export
} from "constants/reports";
import NetworkService from "./NetworkService";

class ReportService {
  getLabsReportBySessionList = (urlParams) => {
    return NetworkService.get(
      `${Method_Get_Session_Reports_List}?${urlParams}`
    ).then((response) => response.data);
  };

  // getLabsReportByUsageList = (urlParams) => {
  //   return NetworkService.get(
  //     `${Method_Get_Usage_Reports_List}?${urlParams}`
  //   ).then((response) => response.data);
  // };

  // getAllEmployeesPartners = (urlParams) => {
  //   return NetworkService.get(
  //     `${Method_Get_User_Type_Report}?${urlParams}`
  //   ).then((response) => response.data);
  // };

  getAllEmployeesPartnersBarChart = (urlParams) => {
    return NetworkService.get(
      `${Method_Get_LabUsage_UserType_BarChart}?${urlParams}`
    ).then((response) => response.data);
  };

  getAllEmployeesPartnersSessionsDounetChart = (urlParams) => {
    return NetworkService.get(
      `${Method_Get_LabUsage_Sessions_DougnetChart}?${urlParams}`
    ).then((response) => response.data);
  };

  getAllEmployeesPartnersTableData = (urlParams) => {
    return NetworkService.get(
      `${Method_Get_Session_Reports_List}?${urlParams}`
    ).then((response) => response.data);
  };

  getExcelLabReport = (urlParams) => {
    return NetworkService.get(
      `${Method_Download_Reports_Data}?${urlParams}`
    ).then((response) => response.data);
  };

  getPDFLabReport = (urlParams) => {
    return NetworkService.get(
      `${Method_Download_Reports_Data_PDF}?${urlParams}`
    ).then((response) => response.data);
  };

  getTop10ByInstances = (urlParams) => {
    return NetworkService.get(`${Method_Top10_Instances}?${urlParams}`).then(
      (response) => response.data
    );
  };

  getTop10ByRunTime = (urlParams) => {
    return NetworkService.get(`${Method_Top10_Run_Time}?${urlParams}`).then(
      (response) => response.data
    );
  };

  getTop10Table = (urlParams) => {
    return NetworkService.get(`${Method_Top10_Table}?${urlParams}`).then(
      (response) => response.data
    );
  };

  getLabReportTop10Session = (urlParams) => {
    return NetworkService.get(
      `${Method_Download_Top10Session_Reports_Data}?${urlParams}`
    ).then((response) => response.data);
  };

  getExcelLabReportTop10RunTime = (urlParams) => {
    return NetworkService.get(
      `${Method_Download_Top10RunTime_Reports_Data}?${urlParams}`
    ).then((response) => response.data);
  };

  getPDFLabReportTop10Session = (urlParams) => {
    return NetworkService.get(
      `${Method_Download_Top10Session_Reports_Data_PDF}?${urlParams}`
    ).then((response) => response.data);
  };

  getPDFLabReportTop10RunTime = (urlParams) => {
    return NetworkService.get(
      `${Method_Download_Top10RunTime_Reports_Data_PDF}?${urlParams}`
    ).then((response) => response.data);
  };

  getGeographyChartData = (urlParams) => {
    return NetworkService.get(`${Method_Geography}?${urlParams}`).then(
      (response) => response.data
    );
  };

  getGeographyDonutChart = (urlParams) => {
    return NetworkService.get(`${Method_Geography_Donut}?${urlParams}`).then(
      (response) => response.data
    );
  };

  getGeographyTable = (urlParams) => {
    return NetworkService.get(`${Method_Get_Table_Country}?${urlParams}`).then(
      (response) => response.data
    );
  };

  getUserCountries = () =>{
    return NetworkService.get(`${Method_Get_All_Country}`).then(
      (response)=>response.data
    )
  }
  getGeographyDownload = (urlParams) =>{
    return NetworkService.get(
      `${Method_Get_Geography_export}?${urlParams}`
    ).then((response) => response.data);
  }
}
export default ReportService;
