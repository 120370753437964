import { METHOD_BACKDOOR_LOGIN, METHOD_SSO_LOGOUT, METHOD_SSO_REDIRECT_URL, METHOD_SSO_VERIFY_TOKEN, METHOD_USERINFO, METHOD_REGISTER, METHOD_FORGOT_PASSWORD, METHOD_RESET_PASSWORD, METHOD_EMAIL_AUTHENTICATE, METHOD_lOGIN } from "constants/login";
import NetworkService from "./NetworkService";

class LoginService {

    getLogoutUrl = async () => {
        const response = await NetworkService.get(METHOD_SSO_LOGOUT);
        return response.data;
    }
    getSsoRedirectUrl = (payload) => {
        return NetworkService.post(METHOD_SSO_REDIRECT_URL, payload).then((response) => response.data);
    }
    verifySsoToken = (payload) => {
        return NetworkService.post(METHOD_SSO_VERIFY_TOKEN, payload).then((response) => response.data);
    }
    getSsoLogoutUrl = () => {
        return NetworkService.get(METHOD_SSO_LOGOUT).then((response) => response.data);
    }

    backdoorLogin = (payload) => {
        return NetworkService.post(METHOD_lOGIN, payload).then((response) => response.data);
    }

    getUserInfo = () => {
        return NetworkService.get(METHOD_USERINFO).then((response) => response.data);
    }

    registerUser = (payload) => {
        return NetworkService.post(METHOD_REGISTER, payload).then((response) => response.data);
    }

    forgotPassword = (payload) => {
        return NetworkService.post(METHOD_FORGOT_PASSWORD, payload).then((response) => response.data);
    }

    resetPassword = (payload) => {
        return NetworkService.post(METHOD_RESET_PASSWORD, payload).then((response) => response.data);
    }

    authenticateEmail = (payload) => {
        return NetworkService.get(`${METHOD_EMAIL_AUTHENTICATE}?token=${payload.token}`, payload).then((response) => response.data);
    }
}
export default LoginService;