import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({

    closeButton : {
        display : "none"
    },
    dragger: {
        width: "15px",
        backgroundColor: "#f4f7f9",
        cursor: "ew-resize",
        padding: "4px 0 0",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 1000
      },
      drawerContent: {
        // maximize space for content
        flex: 1,
        overflow: "auto",
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#CCCED0 0% 0% no-repeat padding-box',
          borderRadius: '2px',
        }
      },
      tabs: {
        borderBottom: '2px solid #E8E8E8',
        margin: '10px 20px 0px 20px',
        '& .HvTab-root::after': {
          backgroundColor: 'unset'
        }
      }
}))