import React from "react";
import { withRouter } from 'react-router';
import "./style.scss";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Delete';
import { MSG_WARNING_DELETE_CATEGORY, MSG_WARNING_DELETE_QUESTION } from "constants/admin/masters/faq";

function Accordian(props) {
    const { data, history, deleteFaqCategory, deleteFaqQuestion, userRole } = props;
    const { url: masterUrl } = props.match;

    const handleDeleteCategory = (event, item) => {
        event.stopPropagation();
        if (window.confirm(MSG_WARNING_DELETE_CATEGORY)) {
            // make api call to delete the category and refresh the data
            deleteFaqCategory(item.id);
        }
    }

    const handleDeleteQuestion = (event, item) => {
        event.stopPropagation();
        if (window.confirm(MSG_WARNING_DELETE_QUESTION)) {
            // make api call to delete the category and refresh the data
             deleteFaqQuestion(item.id);
        }
    }

    const handleEditCategory = (event,item) => {
        event.stopPropagation();
        history.push(`${masterUrl}/edit/category/${item.id}`);
    }

    const handleEditQuestion = (event, item) => {
        event.stopPropagation();
        history.push(`${masterUrl}/edit/question/${item.id}`);
    }

    return (
        <div className="accordian-component">
            {data.map((item, i) => {
                return (
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<div className='icons'> {userRole && <EditIcon onClick={(event) => handleEditCategory(event, item)} />} {userRole && <RemoveIcon onClick={(event) => handleDeleteCategory(event, item)} />}<ExpandMoreIcon className='expandCollapseIcon' /> </div>}
                        ><div className="category">{item.name}</div></AccordionSummary>
                        {item.questions && item.questions.length > 0 && item.questions.map((set, i) => {
                            return (
                                <Accordion key={i}>
                                    <AccordionSummary
                                        expandIcon={<div className='icons'> {userRole && <EditIcon onClick={(event) => handleEditQuestion(event, set)} />} {userRole && <RemoveIcon onClick={(event) => handleDeleteQuestion(event, set)} />} <ExpandMoreIcon className='expandCollapseIcon' /> </div>}
                                    >
                                        <div className="category-question">{set.question}</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* <div className="category-answer" dangerouslySetInnerHTML={{ __html: set.answer }} /> */}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Accordion>
                );
            })}
        </div>
    );
}

export default withRouter(Accordian);