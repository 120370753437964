export const Method_Get_Tools_List = 'web-portal-tool/';
export const Method_Get_Tools_Category = 'tools-category/';

export const toolsMenuOptions = [
    'All Tools',
    'Engineering Operation Service Tools',
    'Flash Efficiency Selling Tool',
    'Partner Tools'
];

export const toolsTabs = [
    {
        index: 0,
        name: 'Tools',
        data: [
            {
                name: 'Storage Economics',
                description: 'Storage Economics is a proven methodology that identifies the costs of storage ownership. With purpose-built tools and services we find costs map investments and build strategies to systematically reduce both CAPEX and OPEX across your IT environment.',
            },
            {
                name: 'Data Reduction Estimator ',
                description: 'Conveniently estimate customer data reduction benefits before implementing Hitachi Vantara deduplication, Storage Virtualization Operating System (SVOS) and flash module drive (FMD) DC2 compression.',
            },
            {
                name: 'Weight and Power ',
                description: 'Quickly calculate your configuration power and weight requirements for your customer. Requires download. Offline tool.',
            },
            {
                name: 'Dynamic Tiering ',
                description: 'This tool will create configurations for the following Platforms: VSP GX00, VSP G1000, HUS VM, HUS100 and VSP. Online tool.',
            },
            {
                name: 'HiPACE ',
                description: 'Quickly calculate your customer’s performance and capacity needs. The HiPACE estimator combines elements of SimpleDiskSizer, COT, HiPAST and weight and power tools to create configurations. Offline tool.',
            },
            {
                name: 'Cash Optimize Tool ',
                description: 'This tool allows you to accurately estimate your customer configurations based on their requirements in minutes. Cash Optimization Tool allows you to store and edit your configurations. Online tool.',
            },

        ],
    },
    {
        index: 1,
        name: 'Assets',
        data: [
            {
                name: 'HiPACE ',
                description: 'Quickly calculate your customer’s performance and capacity needs. The HiPACE estimator combines elements of SimpleDiskSizer, COT, HiPAST and weight and power tools to create configurations. Offline tool.',
            },
            {
                name: 'Cash Optimize Tool ',
                description: 'This tool allows you to accurately estimate your customer configurations based on their requirements in minutes. Cash Optimization Tool allows you to store and edit your configurations. Online tool.',
            },
            {
                name: 'Weight and Power ',
                description: 'Quickly calculate your configuration power and weight requirements for your customer. Requires download. Offline tool.',
            },
            {
                name: 'Storage Economics',
                description: 'Storage Economics is a proven methodology that identifies the costs of storage ownership. With purpose-built tools and services we find costs map investments and build strategies to systematically reduce both CAPEX and OPEX across your IT environment.',
            },
        ],
    },
];
