import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/tools";
import FAQService from "services/admin/masters/FAQService";
import { BootstrapInput } from "containers/labs/style";
import { buildQueryParams } from "utils/common";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import uuid from 'react-uuid';
import RichTextBox from 'components/richTextBox';

const faqServiceObj = new FAQService();

const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { params } = props.match;
    const questionId = params?.questionId;
    const [faqCategoryType, setFaqCategoryType] = useState([]);
    const initialFormData = { // used to initialize the data
        question: '',
        answer: '',
        faq_category_id: 0,
        sort_order:0,

    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "FAQ", link: `/admin/masters/faq` },
            { title: (questionId > 0) ? "Edit FAQ Question" : "Add FAQ Question" },
        ]
    };


    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            question: {
                required: {
                    value: true,
                    message: 'Question is required',
                }
            },
            answer: {
                required: {
                    value: true,
                    message: 'Answer is required',
                }
            },
            faq_category_id: {
                custom: {
                    isValid: (value) => value > 0,
                    message: 'FAQ category is required',
                }
            }
        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {
        let faqCategories = [{ label: '--Select--', value: 0 }];
        let urlParams = { is_deleted: false }
        faqServiceObj.getAllFaqList(buildQueryParams(urlParams)).then(response => {
            if (response) {
                response.results && response.results.length > 0 && response.results.forEach(record => {
                    faqCategories.push({ label: record.name, value: record.id })
                })
                setFaqCategoryType(faqCategories);
            }
        }).catch(error => {
            console.log(error);
        })

        if (questionId > 0) {
            faqServiceObj.getDetailByQuestionId(questionId).then(response => {
                initialFormData.question = response?.question || '';
                initialFormData.answer = response?.answer || '';
                initialFormData.sort_order = response?.sort_order || 0;
                initialFormData.faq_category_id = response?.faq_category_id || 0;
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])


    const submitForm = async (data) => {
        let response;
        if (questionId > 0) {
            response = await faqServiceObj.updateFaqQuestion(data, questionId);
        } else {
            response = await faqServiceObj.createFaqQuestion(data);
        }
        if (response) {
            toast.success(response.message || (questionId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            history.push(`/admin/masters/faq`);
        }

    }

    const bindFaqAnswer = (property, value) => {
        let updatedData = {
            ...data,
            answer: value,
        }
        populateForm(updatedData)
    }

    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} className={classes.questionForm} noValidate>
                        <Box marginBottom={1}>
                            <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 8 }}>Category</Typography>
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                className={classes.select}
                                value={data.faq_category_id}
                                onChange={(event) => {
                                    handleChangeCustom('faq_category_id', event.target.value)
                                }}
                                style={{ width: '100%', fontSize: '32px' }}
                                input={<BootstrapInput />}
                            >
                                {faqCategoryType && faqCategoryType.length > 0 && faqCategoryType.map((option, index) => {
                                    return (
                                        <MenuItem key={uuid()} className={classes.menuItem} value={option.value}><span className='label'>{option.label}</span></MenuItem>
                                    );
                                })}
                            </Select>
                            {(errors['faq_category_id'] && errors['faq_category_id'] !== undefined) ? <p className={classes.errorLabel}>{errors['faq_category_id']}</p> : null}
                        </Box>

                        <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 2 }}>Order</Typography>

                        <TextField fullWidth
                            name="sort_order"
                            variant="outlined"
                            placeholder="sort_order"
                            type='number'
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.sort_order || 0}
                            onChange={handleChange('sort_order')}
                            error={(errors['sort_order'] && errors['sort_order'] !== undefined) ? true : false}
                            helperText={(errors['sort_order'] && errors['sort_order'] !== undefined) ? errors['link'] : null}
                        />


                        <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 2 }}>Question</Typography>
                        <TextField fullWidth
                            name="question"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.question || ''}
                            onChange={handleChange('question')}
                            error={(errors['question'] && errors['question'] !== undefined) ? true : false}
                            helperText={(errors['question'] && errors['question'] !== undefined) ? errors['question'] : null}
                        />
                        <Box marginBottom={2}>
                            <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 8 }}>Answer</Typography>
                            <RichTextBox
                                content={data.answer}
                                onChange={bindFaqAnswer}
                                property='Faq'
                            />
                        </Box>

                        <Grid item xs={12}>
                            <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                <Button className='back-btn' component={Link} to={`/admin/masters/faq`} >
                                    Back
                                </Button>
                                <Button className='add-btn' type="submit" >
                                    {(questionId > 0) ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);