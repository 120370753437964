export const API_URL = process.env.REACT_APP_API_URL;
export const GA_TAG_ID = process.env.REACT_APP_GA_TAG_ID;
export const NAVBAR_LOGO = 'HALO';
export const APP_NAME = process.env.REACT_APP_TITLE;
//other utility constants for SessionStorage
export const LOGGED_IN_USER_INFO = 'HALO-USER-INFO';
export const SHOW_FULLSCREEN = 'SHOW_FULLSCREEN'
export const OKTA_TOKEN_INFO = 'okta-token-storage'
export const SHOW_BANNER = 'SHOW_BANNER';


// Lab Constants
export const RUN_LAB = "Run Lab"
export const SCHEDULE_LAB = "Schedule Lab"
export const VIRTUAL_HARDWARE = "Virtual Hardware"
export const PHYSICAL_HARDWARE = "Physical Hardware"

//API Method Constants
export const METHOD_ADMIN_SETINGS = 'adminSettings/';
export const METHOD_ENCRYPT_PASSWORD = 'encrypt/';

//reducer and action keys
export const SHOW_LOADER = 'SHOW-LOADER';
export const SHOW_PROGRESS = 'SHOW-PROGRESS';
export const ADMIN_SETTINGS = 'ADMIN_SETTINGS';
export const SERVER_ERRORS = 'SERVER_ERRORS';
export const CHANGE_THEME = 'CHANGE_THEME';
export const AUTHENTICATE_OKTA_TOKEN = "AUTHENTICATE_OKTA_TOKEN";
export const CURRENT_PAGE = "CURRENT_PAGE";
export const CURRENT_PAGE_SIZE ="CURRENT_PAGE_SIZE";

//Date Format
export const DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';


//messages
export const GENERAl_ERROR_ADMIN_SETTINGS_FORM = 'There are some error(s) in form, please check all the fields.';
export const SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM = 'Admin Settings updated successfully.';
export const ERROR_MESSAGE_401 = 'Your session has been expired, please login again.';
export const ERROR_MESSAGE_UNKNOWN = 'Unknown error occured, please contact with administrator.';
export const NOTE_MAX_FILTER_SUGGESSTION = 'Note: Please select the filters and click "View Results"';
export const ERROR_MESSAGE_MIN_FILTERS = 'Please select minimum {COUNT} different filters to view the search result';
export const SUCCESS_MESSAGE_COCREATION_FORM = 'Form Submitted Successfully.We will contact you within two business days.'
export const SUCCESS_MESSAGE_FEEDBACK_FORM = 'Feedback Submitted Successfully.'
export const DOWNLOAD_AGREEMENT_MESSAGE = 'Thank you for your interest in using the Hardware Labs. Accepting the Lab Access Agreement is mandatory before using the Hardware Labs, please contact us if you have any questions'
export const PREP_AGREEMENT_MESSAGE = 'Thank you for your interest in using the PREP Labs. Accepting the Lab Access Agreement is mandatory before using the PREP Labs, please contact us if you have any questions'
export const AGREEMENT_MESSAGE = 'Thank you for your interest in using the HALO. Accepting the Lab Access Agreement is mandatory before using the  Labs, please contact us if you have any questions '
export const LAB_ERROR_MESSAGE = "Please wait while labs are loading. If you face any issue, Please contact  halo.support@hitachivantara.com"
export const HALO_SUPPORT_TEAM = "halo.support@hitachivantara.com"
export const PRODUCTION_MAINTENENCE_MESSAGE = "We're conducting scheduled maintenance for the HALO application to introduce a system update. This activity is set to occur on Wednesday, August 28th, 9:30 pm to 12:30 am PST (Thursday, August 29th, 10:00 am to 1:00 pm IST). Please note that the HALO application will be temporarily unavailable during this period."
//length constants
export const MIN_TEXT_LENGTH = 6;
export const MAX_TEXT_LENGTH = 100;

export const pageSizeOptions = [5, 10, 20, 50, 100];

export const MAX_CATEGORY_LIMIT = 100;

export const IDEL_TIMEOUT_MINUTS = process.env.IDEL_TIMEOUT_MINUTS ? process.env.IDEL_TIMEOUT_MINUTS : 2;
export const IDEL_GRACE_TIME_SECONDS = process.env.IDEL_GRACE_TIME_SECONDS ? process.env.IDEL_GRACE_TIME_SECONDS : 60;