import { HvButton, HvTypography } from "@hitachivantara/uikit-react-core";
import { Box, Container } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginService from "services/LoginService";
import { useStyles } from "./style";
import { showBanner } from "redux/actions/bannerActions";

const loginServiceObj = new LoginService();

const Register = () => {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    handleSubmit, // handles form submission
    handleChange, // handles input changes
    handleChangeCustom,
    data, // access to the form data
    errors, // includes the errors to show
  } = useForm({
    // the hook we are going to create
    initialValues: {
      // used to initialize the data
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    validations: {
      // all our validation rules go here
      first_name: {
        required: {
          value: true,
          message: "First Name is required",
        },
        pattern: {
          value: "^[a-zA-Z]+$",
          message: "First Name is not valid",
        },
      },
      last_name: {
        required: {
          value: true,
          message: "Last name is required",
        },
        pattern: {
          value: "^[a-zA-Z]+$",
          message: "Last name is not valid",
        },
      },
      email: {
        required: {
          value: true,
          message: "Email is required",
        },
        pattern: {
          value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
          message: "Email is not valid",
        },
      },
      password: {
        required: {
          value: true,
          message: "Password is required",
        },
        pattern:{
          value : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'<>,.?/]).{8,15}$/,
          message: "Password length should be  8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        }
      },
      confirm_password: {
        required: {
          value: true,
          message: "Password is required",
        },
        custom: {
          isConfirmed: (value) => value === data.password,
          message: "Password did not match.",
        },
      },
    },
    onSubmit: () => {
      handleRegister();
    },
  });

  const handleRegister = () => {
    const payload = {
      full_name: data.first_name + " " + data.last_name,
      email: data.email,
      password: data.password,
      roles: [1], // needs to get removed once backend code is updated
      groups: [2], // needs to get removed once backend code is updated
    };
    loginServiceObj
      .registerUser(payload)
      .then((response) => {
        if (response) {
          dispatch(showBanner({
            payload: {
                showBanner: true,
                label: { message:  "Registration link sent to your email address successfully." },
                variant: "success"
            }
        }))
         
          //   {
          //     showBanner: true,
          //     label: {
          //       message:
          //         "Registration link sent to your email address successfully.",
          //     },
          //     variant: "success",
          //   },
          // ));
          history.push(`/email-login`);
        }
      })
      .catch(() => {});
  };

  const redirectToLogin = () => history.push("/email-login");

  return (
    <div style={{ display: "flex" }}>
      <Container component="main" maxWidth="xs" style={{ display: "contents" }}>
        <img
          src="/assets/images/login.png"
          alt="Login"
          className={classes.responsive}
        />
        <div className={classes.formContainer}>
          <HvTypography variant="mTitle" className={classes.title}>
            Register
          </HvTypography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Box>
              <label htmlFor="first_name" className={classes.label}>
                First Name
              </label>
              <input
                type="text"
                className={classes.input}
                value={data.first_name || ""}
                onChange={handleChange("first_name")}
                placeholder = "Enter Text"
              />
              {errors?.first_name ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.first_name}
                </HvTypography>
              ) : null}
            </Box>
            <Box>
              <label htmlFor="last_name" className={classes.label}>
                Last Name
              </label>
              <input
                type="text"
                className={classes.input}
                value={data.last_name || ""}
                onChange={handleChange("last_name")}
                placeholder = "Enter Text"
              />
              {errors?.last_name ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.last_name}
                </HvTypography>
              ) : null}
            </Box>
            <Box>
              <label htmlFor="email" className={classes.label}>
                Email Address
              </label>
              <input
                type="text"
                className={classes.input}
                value={data.email || ""}
                onChange={handleChange("email")}
                placeholder = "Enter Text"
              />
              {errors?.email ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.email}
                </HvTypography>
              ) : null}
            </Box>
            <Box>
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <input
                type="password"
                className={classes.input}
                onChange={handleChange("password")}
                autoComplete="off"
                placeholder = "Enter Text"
              />
              {errors?.password ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.password}
                </HvTypography>
              ) : null}
            </Box>
            <Box>
              <label htmlFor="confirmpassword" className={classes.label}>
                Confirm Password
              </label>
              <input
                type="password"
                className={classes.input}
                autoComplete="off"
                onChange={handleChange("confirm_password")}
                placeholder = "Enter Text"
              />
              {errors?.confirm_password ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.confirm_password}
                </HvTypography>
              ) : null}
            </Box>
            <Box className={classes.loginButtonContainer}>
              <HvTypography
                variant="highlightText"
                className={classes.or}
                onClick={redirectToLogin}
              >
                Back to Login
              </HvTypography>
              <HvButton
                type="submit"
                category="primary"
                className={classes.loginButton}
                //   className={clsx(classes.submit, classes.sentenceCase)}
              >
                Register
              </HvButton>
            </Box>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Register;
