import React from 'react';
import { withRouter } from 'react-router-dom';
import { HvContainer } from '@hitachivantara/uikit-react-core';
import NewHeader from 'components/NewHeader.jsx';
import { useStyles } from './style.js';

function AppLayout({ location, children }) {
  const classes = useStyles();
  const currentPath = window.location.pathname;
  const isScPortal = currentPath.indexOf('/scportal') === -1;
  const screenSize = window.screen.width;

  return (
    <div className={classes.layoutContainer}>
      {/* <HvCssBaseline /> */}
      {isScPortal && <NewHeader />}
      <div>
        <HvContainer maxWidth={false} className={isScPortal ? classes.mainContainer : classes.scPortalContainer}>
          {children}
        </HvContainer>
      </div>
    </div>
  );
}

export default withRouter(AppLayout);