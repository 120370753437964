import React, { useEffect, useState, useRef } from 'react';
import { HvStepNavigation } from '@hitachivantara/uikit-react-lab';
import { HvGlobalActions, HvGrid, HvTypography, HvBox, HvDialog, HvDialogTitle, HvDialogContent, HvDialogActions, HvButton } from '@hitachivantara/uikit-react-core';
import useStyles from "./LabListStyle";
import GeneralSteps from './labStpes/GeneralSteps';
import ConsoleSteps from './labStpes/ConsoleSteps';
import { Backwards } from '@hitachivantara/uikit-react-icons';
import LabService from "services/LabService";
import { getMasterBaseUrl } from "utils/masterHelpers";
import PermissionSteps from './labStpes/PermissionSteps';
import InstructionsStpes from './labStpes/InstructionsSteps'
import TagSteps from './labStpes/TagSteps';
import ResourceSteps from './labStpes/ResorceSteps';
import CleanupSteps from './labStpes/CleanupSteps';
import { CONFIRM_STEPS_MESSAGE } from 'constants/labs';
const labServiceObj = new LabService();

const LabForm = (props) => {
    const { params, url, history } = props.match;
    const { labId } = params;
    const masterUrl = getMasterBaseUrl(url, params);
    const classes = useStyles();
    const containerRef = useRef(null);
    const [activeStep, setActiveStep] = useState(1);
    const [labData, setLabData] = useState();
    const [mode, setMode] = useState("Add");
    const [confirmationDialog, setConfirmationDialog] = useState(false);

    const setCurrentStep = (number) => {
        setActiveStep(number);
        setLabSteps(labSteps.map((item) => {
            if (item.number < number) {
                return { ...item, state: "Completed" };
            } else if (item.number === number) {
                return { ...item, state: "Current" };
            } else {
                return { ...item, state: "Enabled" };
            }
        }));
    };

    const [labSteps, setLabSteps] = useState([
        {
            onClick: () => { setConfirmationDialog(!confirmationDialog); setActiveStep(1); },
            state: 'Current',
            title: 'General',
            number: 1
        },
        {
            onClick: () => { setConfirmationDialog(!confirmationDialog); setActiveStep(2);},
            state: 'Enabled',
            title: 'Consoles',
            number: 2
        },
        {
            onClick: () => { setConfirmationDialog(!confirmationDialog); setActiveStep(3);},
            state: 'Enabled',
            title: 'Permission',
            number: 3
        },
        {
            onClick: () => { setConfirmationDialog(!confirmationDialog); setActiveStep(4);},
            state: 'Enabled',
            title: 'Instructions',
            number: 4
        },
        {
            onClick: () => { setConfirmationDialog(!confirmationDialog); setActiveStep(5);},
            state: 'Enabled',
            title: 'Tags',
            number: 5
        },
        {
            onClick: () => {setConfirmationDialog(!confirmationDialog);setActiveStep(6); },
            state: 'Enabled',
            title: 'Resources',
            number: 6
        },
        {
            onClick: () => { setConfirmationDialog(!confirmationDialog); setActiveStep(7); },
            state: 'Enabled',
            title: 'Cleanup',
            number: 7
        }
    ])

    const setNextStep = (number) => {
        setActiveStep(number);
        setLabSteps(labSteps.map((item) => {
            if (item.number < number) {
                return { ...item, state: "Completed" };
            } else if (item.number === number) {
                return { ...item, state: "Current" };
            } else {
                return { ...item, state: "Enabled" };
            }
        }));
        setMode("Edit");
    }
    useEffect(() => {
        if (Number(labId) > 0) {
            containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setMode("Edit");
            labServiceObj.getLabDetailsById(labId).then((response) => {
                setLabData(response);
            }).catch((error) => {
                console.log(error);
            })
        }

    }, [labSteps, activeStep])


    const renderCurrentStep = (index) => {
        switch (index) {
            case 1:
                return (<><GeneralSteps labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} />  </>)
            case 2:
                return (<><ConsoleSteps labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} /></>)
            case 3:
                return (<><PermissionSteps labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} /></>)
            case 4:
                return (<><InstructionsStpes labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} /></>)
            case 5:
                return (<><TagSteps labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} /></>)
            case 6:
                return (<><ResourceSteps labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} /></>)
            case 7:
                return (<><CleanupSteps labData={labData} mode={mode} masterUrl={masterUrl} labId={labId} setNextStep={setNextStep} currentStep={activeStep} history={history} /></>)
            default:
                break;
        }

    }


    return (
        <div ref={containerRef}>
            <HvGlobalActions title={<>
                <Backwards onClick={() => { props.history.push("/admin/masters/labs") }} style={{ cursor: "pointer" }} />
                <HvTypography variant="sTitle">
                    {Number(labId) > 0 ? "Edit Lab" : "Add Lab"}</HvTypography>
            </>} className={classes.globalAction}>
                <HvTypography>{Number(labId) > 0 ? labData?.name : ""}</HvTypography>
                <HvBox component='div'>
                    <HvStepNavigation
                        width={{
                            lg: 800,
                            md: 600,
                            sm: 400,
                            xl: 1000,
                            xs: 200
                        }}
                        steps={labSteps}
                        type="Default"
                    /></HvBox>
            </HvGlobalActions >
            <HvGrid container>
                <HvGrid item xs={12}>

                    <HvGrid item xs={12}>
                        {
                            activeStep >= 0 && renderCurrentStep(activeStep)
                        }
                    </HvGrid>

                </HvGrid>
            </HvGrid>
            {
                confirmationDialog ?
                    <HvDialog open={confirmationDialog} onClose={() => { setConfirmationDialog(!confirmationDialog) }}>
                        <HvDialogTitle variant='warning'>Confirm</HvDialogTitle>
                        <HvDialogContent indentContent>
                            {CONFIRM_STEPS_MESSAGE}
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton variant='secondaryGhost' onClick={()=>{
                                setConfirmationDialog(!confirmationDialog);
                                setCurrentStep(activeStep);
                            }}>Confirm</HvButton>
                            <HvButton autoFocus variant='secondaryGhost' onClick={()=>{
                                setConfirmationDialog(!confirmationDialog)
                                setCurrentStep(labSteps.find(step => step.state === 'Current').number)}}>Cancel</HvButton>
                        </HvDialogActions>
                    </HvDialog> : <></>
            }
        </div>


    );
};


export default LabForm;