import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import LabService from "services/LabService";
import { showLoader } from "redux/actions/commonActions";
import './style.scss';
import './styles.css'
import { SplitView } from "./SplitView";
import PropTypes from 'prop-types';
import { showBanner } from "redux/actions/bannerActions";
import { useHistory } from "react-router";
import _ from "lodash";

const labServiceObj = new LabService();

const ScPortal = (props) => {
  const [showTopStrip, settopStrip] = useState(false);
  const { location } = props.history;
  const state = location && location?.state;
  const [showInstructionComp, setInstructionComp] = useState(false);
  const { url, name, id, instruction, video_instruction } = !_.isNull(props.data) &&  !_.isUndefined(props?.data) ? props.data : !_.isNull(state) ? state?.labDetail : "";
  const [labDetail]  = useState(!_.isNull(props.data) &&  !_.isUndefined(props?.data) ? props.data : !_.isNull(state) ? state?.labDetail : "")
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showLoader(true));
  }, [dispatch]);

  const handleOnLoad = () => {
    dispatch(showLoader(false))
    settopStrip(true)
    setInstructionComp(false)
  }

  const hideSideCatalog = () => {
    settopStrip(true);
    setInstructionComp(false)
  }

  const handleInstructions = () => {
    setInstructionComp(!showInstructionComp)
  }

  const terminateLab = () => {
    labServiceObj.terminateLab(labDetail).then((response) => {
      if (_.isEmpty(response)) {
        history.push("/labs")
      }
    }
    ).catch((error) => {
      if(error){
        history.push("/labs")
        dispatch(showBanner({
          payload: {
            showBanner: true,
            label: { message: "Error Occured while terminating Lab." },
            variant: "error"
          }
        }))
      }
      
    });
  }
  const disconnectLab = () => {
    labServiceObj.disconnectLab(labDetail, "runstate=pause").then((response) => {
      if (!_.isEmpty(response)) {   
        history.push("/labs")
      }
    })
      .catch((error) => 
      {
        if(error){
          history.push("/labs")
          dispatch(showBanner({
            payload: {
              showBanner: true,
              label: { message: "Error Occured while disconnecting Lab." },
              variant: "error"
            }
          }))
        }
       
      });
  }

  return (
    <div>
      <SplitView
        showTopStrip={showTopStrip}
        handleInstructions={handleInstructions}
        hideSideCatalog={hideSideCatalog}
        showInstructionComp={showInstructionComp}
        disconnectLab={disconnectLab}
        terminateLab={terminateLab}
        labName={ name ? name : labDetail?.labName}
        instruction={instruction}
        url={url?.replace("/v2/vms/", "/vms/") }
        videoInstruction={video_instruction}
        labId={labDetail?.labId}
      />
    </div>
  )
}

ScPortal.propTypes = {
  data: PropTypes.any,
  history : PropTypes.any
}
export default ScPortal