import React from "react";
import { useSelector } from "react-redux";
import "./spinner.css"
export default function Spinner({children}) {
    const show = useSelector(state => state.commonReducer.showLoader);
    return show ? (
        <div className="spinner">
            {(children) ? children : null}
        </div>
    ) : null;
}