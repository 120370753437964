import React, { useRef, useState } from "react";
import { HvButton, HvDialog, HvDialogTitle, HvDialogContent, HvDialogActions, HvTab, HvTabs, HvTypography, HvContainer, HvStack } from "@hitachivantara/uikit-react-core";
import { Stop, Pause, Open, Home, EditNote } from "@hitachivantara/uikit-react-icons";
import { useStyles } from "./style";
import { useDispatch } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
import SplitPane from "components/SplitBar";
import './styles.css'
import { useHistory } from "react-router";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import _ from "lodash";
import FeedbackDrawer from "containers/newHome/FeebBackDrawer";

const tabsName = [
  {
    index: 0,
    name: 'Text Instructions',
  },
  {
    index: 1,
    name: 'Video Instructions',
  }
];



export const SplitView = ({
  handleInstructions,
  url,
  terminateLab,
  disconnectLab,
  labName,
  instruction,
  videoInstruction,
  downloadScreenshot,
  labId
}) => {
  const [open, setOpen] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [isResizing, setIsResizing] = useState(true);
  const [instructionsOpenedInTab, setInstructionsOpenedInTab] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [openedTabs, setOpenedTabs] = useState([]);
  const [openFeedBackDrawer, setOpenFeedbackDrawer] = useState(false)
  const classes = useStyles();
  const instructionRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const videoArray = !_.isNull(videoInstruction) && !_.isEmpty(videoInstruction) ? videoInstruction?.split(',').map((value) => value.trim()) : [];
  sessionStorage.setItem("Instruction", instruction);
  sessionStorage.setItem("Video", JSON.stringify(videoArray));
  const handleOnLoad = () => {
    dispatch(showLoader(false))
  }

  const openTerminateDialog = () => {
    setOpen(true);
  }

  const openDisconnectDialog = () => {
    setOpenDisconnect(true);
  }
  const stopResizing = () => { setIsResizing(false) };

  const startResizing = () => {
    setIsResizing(true);
  };

  const openInNewTab = () => {
    const newTab = window.open('', '_blank');
    const path = '/instructionInTab'; // Adjust the path as needed
    newTab.location.href = path;
    newTab.document.close();
    setOpenedTabs([...openedTabs, newTab]);
    setInstructionsOpenedInTab(true);
    setIsResizing(false);
  };


  const closeAllTabs = () => {
    openedTabs.forEach((tab) => tab.close());
    setOpenedTabs([]);
  };
  const closeTabsAndTerminate = () => {
    setOpen(false)
    closeAllTabs()
    terminateLab()
    sessionStorage.removeItem("Instruction");
    sessionStorage.removeItem("Video");
  }

  const closeTabsAndDisconnect = () => {
    setOpenDisconnect(false)
    closeAllTabs()
    disconnectLab()
    sessionStorage.removeItem("Instruction");
    sessionStorage.removeItem("Video");
  }


  const handleTabChange = (e, newValue) => setTabIndex(newValue);

  const renderInstruction = (instruction) => (
    <HvContainer className="custom-content" style={{ paddingLeft: "80px", paddingTop: "10px" }}>
      {
        _.isNull(instruction) || _.isEmpty(instruction) || _.isUndefined(instruction) ?
          <HvTypography variant="selectedNavText"> No Text Instructions.</HvTypography> :
          <div
            ref={instructionRef}
            style={{ overflow: "scroll", width: "100%" }} >
            <object data={instruction} aria-label="Url" type="text/html" width={"100%"} height={window.screen.height} onLoad={handleOnLoad} />
          </div>
      }

    </HvContainer>
  )

  const onFailure = () => console.log("player error");
  const onSuccess = () => console.log("player success");

  const renderVideo = (videoArray) => {
    if (videoArray?.length > 0) {
      return (
        videoArray?.map((item) => {
          let urlParts = item?.split('/');
          const accountId = urlParts[3];
          const playerId = urlParts[4]?.split('_')[0];
          const videoId = urlParts[5]?.split('?videoId=')[1];
          if(videoId && accountId){
            return (
              <>
                <HvContainer className="custom-content" style={{ paddingLeft: "80px", paddingTop: "10px" }}>
                  <ReactPlayerLoader accountId={accountId} videoId={videoId} playerId={playerId} onFailure={onFailure}
                    onSuccess={onSuccess}
                    attrs={{
                      responsive: true,
                      autoplay: true,
                      muted: true,
  
                    }}
                    className={classes.videoPlayer}
                  />
  
  
                </HvContainer >
              </>
            )
          }
        
        })
      )

    } else {
      return (
        <>
          <HvTypography variant="selectedNavText" style={{ alignSelf: "center", paddingLeft: "80px", paddingTop: "20px" }}>No Video Instructions.</HvTypography>
        </>
      )
    }
  }


  const handleFeedbacKState = (value) => {
    setOpenFeedbackDrawer(value)
  }

  return (
    <div>
      {
        instructionsOpenedInTab ?
          <SplitPane split="vertical" minSize={0}
            maxSize={-100}
            defaultSize={"95%"} allowResize={false}>
            <div onMouseEnter={stopResizing} style={{ height: "100%" }} >
              <object data={url?.replace("/v2/vms/", "/vms/")} aria-label="Url" type="text/html" width={"100%"} height={"100%"} onLoad={handleOnLoad} />
            </div>
            <div>
              <div style={{ height: window.screen.height, display: "flex" }}>
                <div style={{ width: "5%", background: "#1775E0 0% 0% no-repeat", height: window.screen.height, position: "fixed", display: "flex" }}>
                  <div>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openTerminateDialog} title="Terminate" ><Stop style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openDisconnectDialog} title="Disconnect" ><Pause style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Open Instruction in Tab"}><Open style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} onClick={openInNewTab} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"FeedBack"} ><EditNote onClick={() => { setOpenFeedbackDrawer(!openFeedBackDrawer) }} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Go to Home Page"}><Home style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} onClick={() => { history.push("/labs") }} /></HvButton>
                  </div>
                </div>
                <div style={{ paddingLeft: "70px", flex: '1' }} onMouseEnter={stopResizing} onChange={stopResizing}>
                  <HvDialogTitle>{labName}</HvDialogTitle>
                  <HvTabs id="tabs-main" value={0} className={classes.tabs} style={{ padding: '0 10px' }}>
                    {
                      tabsName.map((tab) => (
                        <HvTab id={tab.index} label={tab.name} />
                      ))
                    }

                  </HvTabs>
                  <HvDialogContent style={{ paddingTop: 20, maxHeight: '75vh' }}>
                    <div ref={instructionRef} dangerouslySetInnerHTML={{ __html: instruction }} />
                  </HvDialogContent>
                </div>
              </div>
            </div>
          </SplitPane> :
          <>

            <SplitPane split="vertical" minSize={0}
              maxSize={-100}
              defaultSize={"50%"} allowResize={isResizing} onDragFinished={stopResizing} onResizerClick={startResizing} step={2} primary="second" >
              <div onMouseEnter={stopResizing} style={{ height: "100%" }} >
                <object data={url?.replace("/v2/vms/", "/vms/")} type="text/html" aria-label="Url" width={"100%"} height={"100%"} onLoad={handleOnLoad} onMouseEnter={stopResizing} /></div>
              <div>
                <div style={{ height: window.screen.height, overflow: "auto" }} >
                  <HvStack style={{ background: "#1775E0 0% 0% no-repeat", height: window.screen.height, position: "absolute" }} direction="column">
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openTerminateDialog} title="Terminate" ><Stop style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={openDisconnectDialog} title="Disconnect"><Pause style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Open Instruction in Tab"}><Open onClick={openInNewTab} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"FeedBack"} ><EditNote onClick={() => { setOpenFeedbackDrawer(!openFeedBackDrawer) }} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                    <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} title={"Go to Home Page"}><Home onClick={() => { history.push("/labs") }} style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }} /></HvButton>
                  </HvStack>

                  <div onMouseEnter={stopResizing} onChange={stopResizing}>

                    <HvTypography variant="title2" style={{ paddingLeft: "80px", paddingTop: "10px" }}>{labName}</HvTypography>
                    <HvTabs id="tabs-main" value={tabIndex} className={classes.tabs} style={{ paddingLeft: "80px", paddingTop: "10px" }} onChange={handleTabChange}>
                      {
                        tabsName.map((tab) => (
                          <HvTab id={tab.index} label={tab.name} value={tab.index} onChange={handleTabChange} />
                        ))
                      }
                    </HvTabs>
                    {
                      tabIndex === 0 && renderInstruction(instruction)
                    }

                    {
                      tabIndex === 1 && renderVideo(videoArray)
                    }


                  </div>
                </div>
              </div>
            </SplitPane>

          </>
      }

      {
        open ? <div>
          <HvDialog
            disableBackdropClick
            open={open}
            onClose={() => setOpen(false)}
            id="terminate"
            classes={{
              closeButton: classes.closeButton
            }}>
            <HvDialogTitle variant="warning">Terminate Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              If you have just launched the lab then please wait for 5 mins and then terminate.
              Terminate lab will end the lab. You need to run the lab again.
            </HvDialogContent>
            <HvDialogActions>
              <HvButton id="apply" onClick={closeTabsAndTerminate} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton id="cancel" variant="primaryGhost" onClick={() => setOpen(false)}>
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div> : <></>
      }
      {
        openDisconnect ? <div >
          <HvDialog
            disableBackdropClick={true}
            open={openDisconnect}
            id="disconnect"
            classes={{
              closeButton: classes.closeButton
            }}
          >
            <HvDialogTitle variant="warning">Disconnect Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              Disconnect lab will pause the Lab.You can access it from Running Labs section.
            </HvDialogContent>
            <HvDialogActions>
              <HvButton id="apply" onClick={closeTabsAndDisconnect} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton id="cancel" variant="primaryGhost" onClick={() => setOpenDisconnect(false)}>
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div> : <></>
      }
      {
        openFeedBackDrawer ? <div>
          <FeedbackDrawer labId={labId} openDrawer={openFeedBackDrawer} handleDrawerState={handleFeedbacKState} />
        </div> : <></>
      }
    </div>


  );
};
