import React, { useEffect, useState, useRef } from 'react';
import { HvGrid, HvTypography, HvDropdown, HvInput, HvRadio, HvButton, HvSection } from '@hitachivantara/uikit-react-core';
import ConsoleService from 'services/admin/masters/Console';
import Footer from 'components/footer/Footer';
import { useHistory } from 'react-router';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';
import { buildQueryParams, getSelectedIdFromArray } from 'utils/common';
import { Delete } from '@hitachivantara/uikit-react-icons';
import { useDispatch } from 'react-redux';
import { showLoader } from 'redux/actions/commonActions';

const ConsoleSteps = props => {
    const { currentStep } = props
    const [labId] = useState(props?.labData?.id ? props?.labData?.id : props?.labId);
    const inputRefs = useRef({});
    const [deleteConsole, setDeleteConsole] = useState(false);
    const [initialValues, setInitialValues] = useState({
        cloud_type_id: "SkyTap",
        console_type_id: "RDP",
        name: '',
        template: 0,
        lab_console_access_type_id: 1,
        updated_by: '',
        max_parallel_session: '',
        sort_order: '',
        lab: labId
    });

    const [skyTapdropdownMenuItems] = useState([
        { label: "SkyTap", value: "SkyTap", selected: true }
    ])
    const [consoleTypedropdownMenuItems] = useState([
        { label: "RDP", value: "RDP", selected: true }
    ])
    const [templateData, setTemplateData] = useState([]);
    const [vmAccess, setVmAccess] = useState([]);
    const [vAppId, setVAppId] = useState();
    const [vmObjectId, setVmObjectId] = useState()
    const consoleServiceObj = new ConsoleService();
    const dispatch = useDispatch();

    const handleChangeTemplateVmAccess = (value) => {
        let VM_ACCESS
        consoleServiceObj.getAllTemplateVM(value).then((response) => {
            if (response?.template_details.vms.length === 1){
                VM_ACCESS = response?.template_details.vms.map((v1) => {
                    return { name: v1.name, include: true, exclude: false, default: true }
                })
            }else{
                VM_ACCESS = response?.template_details.vms.map((v1) => {
                    return { name: v1.name, include: true, exclude: false, default: false }
                })
            }
            
            setVmAccess(VM_ACCESS)
        }).catch((error) => {
            console.log(error)
        })
    }

    const createConsolePayload = (key, value) => {
        setInitialValues({
            ...initialValues,
            [key]: value
        });
    }

    useEffect(() => {
        let templateList = [];
        if (props.mode === "Edit") {
            let params = {
                is_deleted: 'false',
                lab: labId
            }
            consoleServiceObj.getAll(buildQueryParams(params)).then((response) => {
                const { results } = response;
                if (results.length !== 0) {
                    setDeleteConsole(true)
                    setVAppId(results[0]?.id)
                }

                if (results[0]?.id>0) {
                    let params = {
                        id: results[0]?.id
                    }
                    consoleServiceObj.getById(params).then((response) => {
                        if (response) {
                            initialValues.name = response?.name;
                            initialValues.updated_by = response?.updated_by;
                            initialValues.max_parallel_session = JSON.stringify(response?.max_parallel_session);
                            initialValues.sort_order = JSON.stringify(response?.sort_order);
                            initialValues.template = response?.template
                        }
                        if (results[0]?.id>0) {
                            consoleServiceObj.getALLVmAccess(labId).then((response) => {
                                if (response.results[0].vm_details.length === 1) {
                                    const element = response.results[0].vm_details[0]
                                    element.include = true;
                                    element.exclude = false;
                                    setVmAccess(response.results[0].vm_details.map(el => el.name === element.name ? element : el))
                                } else {

                                    setVmAccess(response.results[0].vm_details)
                                }
                                setVmObjectId(response.results[0].id)
                            }).catch(error => {
                                console.log(error)
                            })
                        }
                    }).catch((err) => {
                        console.log(err)
                    })
                }

            }).catch((error) => {
                console.log(error);
            })

            consoleServiceObj.getTemplateList().then((response) => {
                const { templates } = response;
                templates && templates.length > 0 && templates.forEach(element => {
                    if (initialValues.template === element?.id) {
                        templateList.push({ label: element.name, value: element.id, selected: true });
                    } else {
                        templateList.push({ label: element.name, value: element.id, selected: false });
                    }

                });
                setTemplateData(templateList);
            }).catch(error => {
                console.log(error)
            });

        }


    }, [])

    const setRadioButtonChecked = (event) => {
        let element = {}, updatedItems = [];
        switch (event.target.id) {
            case "include-input":
                element = vmAccess.find((v1) => v1?.name === event.target.name)
                element.include = true
                element.exclude = false
                updatedItems = vmAccess.map(el => el.name === element.name ? element : el)
                setVmAccess(updatedItems)
                break;
            case "exclude-input":
                element = vmAccess.find((v1) => v1?.name === event.target.name)
                element.include = false
                element.exclude = true
                element.default = false
                updatedItems = vmAccess.map(el => el.name === element.name ? element : el)
                setVmAccess(updatedItems)
                break;
            case "default-input":
                element = vmAccess.find((v1) => v1?.name === event.target.name)
                element.include = true
                element.exclude = false
                element.default = true
                updatedItems = vmAccess.map((el) => {
                    if (el.name === element.name) { return element }
                    else {
                        if (el.default === true) { {return {...el, default:false}} }
                        else { return el }
                    }
                })
                setVmAccess(updatedItems)
                break;
            default:

                break;
        }
    }

    const handleConsoleSave = () => {
        if (props.mode === "Edit" && deleteConsole) {
            consoleServiceObj.update(initialValues, vAppId).then((response) => {
                if (response?.lab && vmObjectId) {
                    consoleServiceObj.updateAllVmAcces([{ vm_details: vmAccess }], vmObjectId).then((response) => {
                        props.setNextStep(currentStep + 1);
                    }).catch((err) => {
                        console.log(err);
                    })
                }else{
                    consoleServiceObj.setAllVmAcces({ lab: labId, vm_details: vmAccess }).then((response) => {
                        props.setNextStep(currentStep + 1);
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            })
        } else {
            consoleServiceObj.create(initialValues).then((response) => {
                if (response) {
                    consoleServiceObj.setAllVmAcces({ lab: labId, vm_details: vmAccess }).then((response) => {
                        props.setNextStep(currentStep + 1);
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            })
        }
    }
    const deleteConsoleTemplate = () => {
        consoleServiceObj.delete(vAppId).then(() => {
            setInitialValues({
                cloud_type_id: "SkyTap",
                console_type_id: "RDP",
                name: '',
                template: 0,
                lab_console_access_type_id: 1,
                updated_by: '',
                max_parallel_session: '',
                sort_order: '',
                lab: labId
            })

            setTemplateData(templateData.map(element => {
                return { ...element, selected: false }
            }));
            setVmAccess([]);
            setDeleteConsole(false);
        })
    }
    return (
        <div>
            <form autoComplete='on' onSubmit={(event) => {
                event.preventDefault();
                handleConsoleSave()
            }}>
                <HvGrid container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <HvGrid item xs={12} style={{ alignSelf: "center", display: "flex" }}>
                        <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}><HvTypography variant='sTitle'>Consoles</HvTypography></HvGrid>
                        {deleteConsole ? <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}> <HvButton variant='ghost' title='Delete Console' onClick={deleteConsoleTemplate}><Delete title='Delete Console'></Delete></HvButton> </HvGrid> : <></>}
                    </HvGrid>
                    <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvDropdown values={skyTapdropdownMenuItems} label={"Cloud Type"} />
                    </HvGrid>
                    <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvDropdown values={consoleTypedropdownMenuItems} label={"Cloud Type"} />
                    </HvGrid>
                    <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvInput label="Name" required onChange={(e) => { createConsolePayload("name", e.target.value); }} value={initialValues.name} />
                    </HvGrid>
                    <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvDropdown values={templateData} label={"Template"} onChange={(event) => {
                            handleChangeTemplateVmAccess(event?.value)
                            createConsolePayload("template", Number(event?.value))
                        }}
                        />
                    </HvGrid>
                    <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvInput label="LastModified By" readOnly={true} value={initialValues.updated_by} />
                    </HvGrid>
                    {
                        vmAccess?.length > 0 ?
                            vmAccess?.length === 1 ?
                                <HvGrid container style={{ paddingLeft: "30px", paddingTop: "30px" }}>
                                    <HvGrid item xs={12} style={{ alignSelf: "center" }}>
                                        <HvTypography variant='sTitle'>VM Permission</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={4} lg={3} sm={6} style={{ justifyContent: "center" }}>
                                        <HvTypography variant='title4'>VM Name</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                        <HvTypography variant='title4'>Include</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                        <HvTypography variant='title4'>Default</HvTypography>
                                    </HvGrid>
                                    {
                                        vmAccess.map((v1) => {
                                            return (
                                                <>
                                                    <HvGrid container style={{ paddingLeft: "30px", paddingTop: "30px" }}>
                                                        <HvGrid item xs={6} xl={4} lg={3} sm={6} style={{ justifyContent: "center" }}>
                                                            <HvTypography variant='label'>{v1.name}</HvTypography>
                                                        </HvGrid>
                                                        
                                                        <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                                            <HvRadio name={v1?.name} id="include" defaultChecked={v1.include} checked={v1.include} onChange={(event) => { setRadioButtonChecked(event) }} />
                                                        </HvGrid>
                                                        <HvGrid item xxs={6} xl={2} lg={3} sm={6} >
                                                            <HvRadio name={v1?.name} id="default" defaultChecked={v1.default} checked={v1.default} onChange={(event) => { setRadioButtonChecked(event) }} />
                                                        </HvGrid>
                                                    </HvGrid>
                                                </>
                                            )

                                        })
                                    }
                                </HvGrid> :

                                <HvGrid container style={{ paddingLeft: "30px", paddingTop: "30px" }}>
                                    <HvGrid item xs={12} style={{ alignSelf: "center" }}>
                                        <HvTypography variant='sTitle'>VM Permission</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={4} lg={3} sm={6} style={{ justifyContent: "center" }}>
                                        <HvTypography variant='title4'>VM Name</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                        <HvTypography variant='title4'>Include</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                        <HvTypography variant='title4'>Exclude</HvTypography>
                                    </HvGrid>
                                    <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                        <HvTypography variant='title4'>Default</HvTypography>
                                    </HvGrid>
                                    {
                                        vmAccess.map((v1) => {
                                            return (
                                                <>
                                                    <HvGrid container style={{ paddingLeft: "30px", paddingTop: "30px" }}>
                                                        <HvGrid item xs={6} xl={4} lg={3} sm={6} style={{ justifyContent: "center" }}>
                                                            <HvTypography variant='label'>{v1.name}</HvTypography>
                                                        </HvGrid>
                                                        <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                                            <HvRadio name={v1?.name} id="include" defaultChecked={v1.include} checked={v1.include} onChange={(event) => { setRadioButtonChecked(event) }} />
                                                        </HvGrid>
                                                        <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                                            <HvRadio name={v1?.name} id="exclude" defaultChecked={v1.exclude} checked={v1.exclude} onChange={(event) => { setRadioButtonChecked(event) }} />
                                                        </HvGrid>
                                                        <HvGrid item xs={6} xl={2} lg={3} sm={6} >
                                                            <HvRadio name={v1?.name} id="default" defaultChecked={v1?.default} checked={v1?.default} onChange={(event) => { setRadioButtonChecked(event) }} />
                                                        </HvGrid>
                                                    </HvGrid>
                                                </>
                                            )

                                        })
                                    }
                                </HvGrid>
                            : <></>
                    }
                    <HvGrid item xs={12} style={{ alignSelf: "center" }}>
                        <HvTypography variant='sTitle'>Others</HvTypography>
                    </HvGrid>
                    <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvInput label="Max Instances" type='number' onChange={(e) => { createConsolePayload("max_parallel_session", e.target.value) }} value={initialValues.max_parallel_session} />
                    </HvGrid>
                    {/* <HvGrid item md={6} xs={3} xl={6} lg={6} sm={6}>
                        <HvInput label="Order" type='number' onChange={(e) => { createConsolePayload("sort_order", e.target.value) }} value={initialValues.sort_order} />
                    </HvGrid> */}

                </HvGrid>
                <Footer handleSumbit={(event) => { event?.preventDefault() }} />
            </form>
        </div>
    );
};

ConsoleSteps.propTypes = {

};

export default ConsoleSteps;