import { HvButton, HvTypography } from "@hitachivantara/uikit-react-core";
import { Box, Container } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginService from "services/LoginService";
import { useStyles } from "./style";
import { showBanner } from "redux/actions/bannerActions";

const loginServiceObj = new LoginService();

const ForgotPassword = () => {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const {
    handleSubmit, // handles form submission
    handleChange, // handles input changes
    data, // access to the form data
    errors, // includes the errors to show
  } = useForm({
    // the hook we are going to create
    initialValues: {
      // used to initialize the data
      email: "",
      confirm_email: "",
    },
    validations: {
      // all our validation rules go here
      email: {
        required: {
          value: true,
          message: "Email is required",
        },
        pattern: {
          value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
          message: "Email is not valid",
        },
      },
      confirm_email: {
        required: {
          value: true,
          message: "Email is required",
        },
        custom: {
          isConfirmed: (value) => value === data.email,
          message: "Email did not match.",
        },
      },
    },
    onSubmit: () => {
      handleResetPassword();
    },
  });

  const handleResetPassword = () => {
    const payload = {
      email: data.email,
      confirm_email: data.confirm_email,
    };
    loginServiceObj
      .forgotPassword(payload)
      .then((response) => {
        if (response) {
          dispatch(showBanner({
            payload: {
                showBanner: true,
                label: { message:  response.message },
                variant: "success"
            }
        }))
        }
      })
      .catch(() => {});
  };

  const redirectToLogin = () => history.push("/email-login");

  return (
    <div style={{ display: "flex" }}>
      <Container component="main" maxWidth="xs" style={{ display: "contents" }}>
        <img
          src="/assets/images/login.png"
          alt="Login"
          className={classes.responsive}
        />
        <div className={classes.formContainer}>
          <HvTypography variant="mTitle" className={classes.title}>
            Reset Password
          </HvTypography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Box>
              <label htmlFor="email" className={classes.label}>
                Email Address
              </label>
              <input
                type="text"
                className={classes.input}
                value={data.email || ""}
                onChange={handleChange("email")}
                placeholder = "Enter Text"
              />
              {errors?.email ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.email}
                </HvTypography>
              ) : null}
            </Box>
            <Box>
              <label htmlFor="confirmemail" className={classes.label}>
                Confirm Email
              </label>
              <input
                type="email"
                className={classes.input}
                value={data.confirm_email || ""}
                onChange={handleChange("confirm_email")}
                placeholder="Enter Text"
              />
              {errors?.confirm_email ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.confirm_email}
                </HvTypography>
              ) : null}
            </Box>
            <Box className={classes.loginButtonContainer}>
              <HvTypography
                variant="highlightText"
                className={classes.or}
                onClick={redirectToLogin}
              >
                Back to Login
              </HvTypography>
              <HvButton
                type="submit"
                category="primary"
                className={classes.loginButton}
                //   className={clsx(classes.submit, classes.sentenceCase)}
              >
                Submit
              </HvButton>
            </Box>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ForgotPassword;
