import React, { useEffect, useState, useRef, Fragment } from 'react';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { profileMenu } from 'constants/profileMenu';
import { checkPermissions } from 'utils/masterHelpers';
import uuid from 'react-uuid';
import './style.scss';
import { LogOut, OpenBook } from "@hitachivantara/uikit-react-icons";
import ThemeSwtich from './ThemeSwtich';
import store from "redux/store";
import { CHANGE_THEME, LOGGED_IN_USER_INFO } from "constants/common";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from '@okta/okta-react';
import { OKTA_TOKEN_INFO, SHOW_BANNER } from "constants/common";
import { LANGUAGE_GENERAL_SSO_ERROR } from 'constants/login';
import LoginService from "../../services/LoginService";
import { withRouter } from 'react-router';
import { showBanner } from 'redux/actions/bannerActions';
import { useAuth0 } from "@auth0/auth0-react";

function useOutsideAlerter(ref, setProfileMenu) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (event.target.id !== 'user-profile') {
          setProfileMenu(false)
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setProfileMenu]);
}


function NestedMenu(props) {
  const [currentMenus, setCurrentMenus] = useState(profileMenu);
  const [previousStack, setPreviousStack] = useState([]);
  const [state, setState] = useState(false);
  const { userInfo, setProfileMenu } = props;
  const { full_name, role } = userInfo;
  const userRoles = Object.values(userInfo?.roles[0]);
  const userGroups = userInfo?.groups?.map(group => group.id);
  const wrapperRef = useRef(null);
  const theme = useSelector((state) => state.themeReducer.theme);
  const dispatch = useDispatch();
  const loginServiceObj = new LoginService();
  useOutsideAlerter(wrapperRef, setProfileMenu);
  const { isAuthenticated, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_returnTo,
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID
      }
    });

  const redirectTo = (url) => {
    const data = localStorage.getItem(OKTA_TOKEN_INFO);
    const token = data ? JSON.parse(data) : {}
    const { history } = props;
    if (url === "/logout") {
      localStorage.clear("LabUrl");
      if (isAuthenticated) {
        let logouturl = "";
        try {
          loginServiceObj.getLogoutUrl().then(async result => {
            if (result?.logouturl) {
              logouturl = result?.logouturl;
              sessionStorage.clear(LOGGED_IN_USER_INFO);
              logoutWithRedirect().then(() => {
                dispatch(showBanner({
                  payload: {
                    showBanner: true,
                    label: { message: "You have been successfully logged out." },
                    variant: "success"
                  }
                }))
              })
            }
          }).catch(() => {
            store.dispatch({
              type: SHOW_BANNER,
              payload: {
                showBanner: true,
                label: { message: LANGUAGE_GENERAL_SSO_ERROR },
                variant: "error"
              }
            })
          });

        } catch (err) {
          console.log(err)
        }
      } else {
        loginServiceObj.getLogoutUrl().then(async result => {
          if (result?.logouturl) {
            window.location.href = result.logouturl;
            sessionStorage.clear(LOGGED_IN_USER_INFO);
            store.dispatch({
              type: SHOW_BANNER,
              payload: {
                showBanner: true,
                label: { message: "You have been logged out." },
                variant: "success"
              }
            })

          }

        }).catch(() => {
          store.dispatch({
            type: SHOW_BANNER,
            payload: {
              showBanner: true,
              label: { message: LANGUAGE_GENERAL_SSO_ERROR },
              variant: "error"
            }
          })
        });

      }
    } else {
      history.push(url);
      setProfileMenu(false)
    }


  }



  const changeTheme = (_evt, newChecked) => {
    setState(newChecked);
    store.dispatch({
      type: CHANGE_THEME,
      payload: newChecked ? "wicked" : "dawn"

    });

  }

  const renderMenuItems = data => {
    return data.map(item =>
      (item?.children && item?.children.length) ?
        checkPermissions(item, userRoles, userGroups) ? (
          <Fragment key={uuid()}>
            <div className='user-roles' onClick={() => {
              previousStack.push(data);
              setPreviousStack(previousStack);
              setCurrentMenus(item.children)
            }}>
              <div className='menu'>
                <img className='menu-icon' src={item.icon} alt='' />
                <span className='menu-name'>{item.title}</span>
                {/* <ExpandMoreIcon className='accordian' /> */}
              </div>
            </div>
          </Fragment>
        ) : null
        : checkPermissions(item, userRoles, userGroups) ? (
          <>
            <Fragment key={uuid()}>
              <div className='user-roles'>
                <div className='menu' style={{ borderBottom: "#0000001c 1px solid" }} onClick={() => {
                  redirectTo(item.url)
                }}>
                    <img className='menu-icon' src={item.icon} alt='' style={{paddingTop:'5px'}}/>
                    <span className='menu-name'>{item.title}</span>
                </div>
              </div>
            </Fragment>
          </>
        ) : null
    )
  }


  useEffect(() => {
    theme === "wicked" ? setState(true) : setState(false)
  }, [theme])


  return (
    <>
      <div className='dropdown-content' ref={wrapperRef}>
        <div className='profile-container'>
          {(previousStack.length) ?
            <div className='submenu-header'>
              {/* <ExpandMoreIcon className='backarrow' onClick={e => {
                const prevState = previousStack.pop();
                setPreviousStack(previousStack);
                setCurrentMenus(prevState);
              }} /> */}
              <span className='submenu-heading'>{currentMenus[0].parentTitle}</span>
            </div>

            :
            <div className='profile-header'>
              <span className='user-name'>{full_name}</span>
              {/* <span className='user-details'>{role}</span> */}

            </div>
          }
          {/* <div  className='profile-header' style={{ paddingBottom: "15px", borderBottom: "#0000001c 1px solid" }}>
            <span className='user-name'>Switch Theme</span>
            <ThemeSwtich state={state} changeTheme={changeTheme} />
          </div> */}
          {
            renderMenuItems(currentMenus)
          }


        </div>
      </div>
    </>
  )
}

export default withRouter(NestedMenu);