import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/loginActions';
import {
    HvButton,
    HvDialog,
    HvDialogActions,
    HvDialogContent,
    HvDialogProps,
    HvDialogTitle,
} from "@hitachivantara/uikit-react-core";

const InactivityMonitor = ({ timeout, graceTime }) => {
    const dispatch = useDispatch();
    const [isIdle, setIsIdle] = useState(false);
    const [remainingTime, setRemainingTime] = useState(graceTime);

    const handleOnIdle = () => {
        setIsIdle(true);

    };
    useEffect(() => {
        let timerInterval;
        if (isIdle) {
            timerInterval = setInterval(() => {
                setRemainingTime(prevTime => Math.max(0, prevTime - 1));

            }, 1000);
        } else {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval);
    }, [isIdle]);
    useEffect(() => {

        if (remainingTime == 0) {
            handleLogout();
        }

    }, [remainingTime]);
    const handleOnActive = () => {
        if (remainingTime == 0) {
            setIsIdle(false);
        }
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        onActive: handleOnActive
    });

    const handleContinue = () => {
        setIsIdle(false);
        setRemainingTime(graceTime);

    };

    const handleLogout = () => {

        dispatch(logout());
        return true;
    };

    return (
        <>
            {isIdle && (
                <>

                    <HvDialog open={true} onClose={handleContinue} >
                        <HvDialogTitle variant="warning">Session Time Out</HvDialogTitle>
                        <HvDialogContent indentContent>
                            <p>You have been idle for some time. Do you want to continue your session?</p>
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton variant="secondaryGhost" onClick={handleContinue}>
                                Stay logged In({remainingTime} seconds)
                            </HvButton>
                            <HvButton
                                autoFocus
                                variant="secondaryGhost"
                                onClick={handleLogout}
                            >
                                No, log out
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </>
            )}
        </>
    );
};

export default InactivityMonitor;
