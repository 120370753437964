
import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 205;
export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appContainer: {
        overflow: 'auto',
        height: '100%'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    toolbar: {
        borderTop: '5px solid #ce0000!important',
        background: '#fff',
        // " & .MuiButtonBase-root": {
        //     " & .MuiIconButton-label": {
        //         " & .MuiSvgIcon-root": {
        //             color: '#000',
        //         }
        //     }
        // }
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        // " & .MuiButtonBase-root": {
        //     width: '100px',
        // }
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    profileSection: {
        border: '1px solid #2a2c2e',
        borderRadius: '50%',
        padding: '8px 10px 8px 10px',
        cursor: 'pointer',
        '@media(min-width:320px) and (max-width:490px)': {
            padding: '4px 6px 4px 6px',
        },
    },
    accordian: {
        fontSize: '20px',
        transform: 'rotate(90deg)',
        margin: 'auto',
        height: 16,
        width: 16,
    },
    textfield: {
        border: '1px solid #2a2c2e',
        width: '28%',
        borderRadius: '4px',
        marginRight: '30px',
    },
    bootstrapInput: {
        color: '#2a2c2e',
        fontSize: 14,
        paddingLeft: 6,
        paddingRight: 24,
    },
    logo: {
        width: 90,
        marginTop: 15,
        cursor: 'pointer',
        '@media(min-width: 320px) and (max-width:490px)': {
            width: 76,
        }
    },
    sidebarContainer: {
        // "& .MuiButtonBase-root": {
        //     color: '#fff',
        //     " & .MuiTypography-root": {
        //         fontSize: '14px',
        //     },
        // },
        "& .MuiListItemIcon-root": {
            minWidth: '40px',
            color: '#fff',
        },
        " & .collapsed": {
            display: 'block',
            padding: '10px 0px 10px 0px;',
            " & .MuiListItemIcon-root": {
                paddingLeft: '16px',
                paddingRight: '16px',
                color: '#fff',
            },
            " & .MuiListItemText-root": {
                margin: '0px',
                textAlign: 'center',
                "& >span": {
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginBottom: '16px',
                    color: '#fff',
                }
            }
        },
    },
    "@media(max-width:767px)": {
        headerContainer: {
            " & .MuiToolbar-root": {
                " & .MuiIconButton-edgeStart": {
                    display: 'none',
                }
            }
        }
    },
    contentGrid: {
        '& .MuiTable-root': {
            borderRadius: '4px'
        }
    },
    contentRoot: {
        flexGrow: 1,
        backgroundColor: '#fff',
        padding: '16px',
        margin: '16px 0px 16px 0px',
        borderRadius: '4px',
        position: 'relative',
        '& .MuiFormControl-root': {
            margin: '6px 0px 6px 0px',
        },
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "#f5f5f5",
            boxShadow: 'none',
            borderBottom: '1px solid #c2c2c2',
        },
        "& .MuiTab-root": {
            padding: '10px 24px 0px 24px'
        },
        "& .tabs-panel": {
            marginTop: '24px',
            borderRadius: '4px',
            "& .MuiBox-root": {
                padding: '8px 8px 4px 8px',
                fontSize: '12px',
                // width:'100%',
                '@media(min-width:767px) and(max-width:802px)': {
                    paddingLeft: '40px',
                },
                "& .MuiTypography-root": {
                    "& .MuiBox-root": {
                        "& .MuiTypography-root": {
                            "& .MuiIconButton-root": {
                                padding: '0px',
                            },
                        }
                    }
                }
            }
        },
        "& .tabs-container": {
            marginTop: '8px',
        },
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "#bd0912",
            border: '2px solid #bd0912'
        },
        // "& .MuiButtonBase-root.MuiTab-root": {
        //     fontSize: 12,
        //     color: "#333333",
        //     "@media(max-width:932px)": {
        //         minWidth: '0px',
        //         padding: '8px 12px 0px 12px',
        //     },
        //     "& .MuiTab-wrapper": {
        //         flexDirection: 'row',
        //         justifyContent: 'center'
        //     }
        // },
    },
    brandLink: {
        display: 'flex',
        color: '#1d1d1d',
        fontSize: '14px',
        fontWeight: '600'
    },
    link: {
        display: 'flex',
        fontSize: '14px',
    },
    masterBtn: {
        "& .MuiButton-root": {
            background: '#1d1d1d',
            color: '#fff',

            textTransform: 'none',
        },
        "& .MuiButton-root:hover": {
            background: '#1d1d1d',
        },
        "& .add-btn": {
            background: "#1d1d1d",
            color: "#fff",
            fontSize: 14,
        },
        "& .back-btn": {
            background: '#fff',
            color: '#1d1d1d',
            border: '1px solid #1d1d1d',
            fontSize: 14,
            marginRight: 16,
        },
        "& .back-btn:hover": {
            background: '#fff',
        }
    },
    toggleBtnContainer: {
        marginTop: 12,
        " & .MuiToggleButton-root": {
            color: '#2a2c2e',
            fontSize: 16,
            textTransform: 'none',
            border: 'none',
            borderRadius: 0,
            padding: '0px 12px 0px 12px'
        },
    },
    masterGrid: {
        marginTop: 16,
        "& .MuiTableCell-paddingCheckbox:last-child": {
            textAlign: 'left !important',
            paddingLeft: 16,
        }
    },
    inputField: {
        fontSize: '14px !important',
    },
    base: {
        '&$focused $notchedOutline': {
            borderColor: '#2a2c2e',
            border: '1px solid #2a2c2e',
        },
    },
    focused: {
    },
    notchedOutline: {
        border: "1px solid #2a2c2e !important",
    },
    outerContainer: {
        padding: '24px 24px 0px 24px',
    },
    errorLabel: {
        color: '#f44336',
        fontSize: '0.75rem',
        marginTop: '3px',
        //marginLeft: '14px'
    },
    inputLabel: {
        fontSize: 14,
        color: '#000',
        fontWeight: 500,
    },

    videoRightPanel: {
        cursor: 'pointer',
        width: '3%',
        display: 'inline-flex',
        justifyContent: 'right',
        "@media(min-width:768px) and (max-width:1024px)": {
            width: '5%',
        }
    },
    videoLeftPanel: {
        display: 'inline-flex',
        width: '97%',
        "@media(min-width:768px) and (max-width:1024px)": {
            width: '95%',
        }
    },
    readOrHide: {
        color: '#1874CD',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    select: {
        minWidth: 225,
    },
    menuItem: {
        fontSize: 14,
        display: 'flex',
        " & .label": {
            fontSize: 14,
            color: '#2a2c2e',
            margin: 'auto 0'
        },
    },
    supportQuestion: {
        padding: 12,
        background: '#fff',
        borderRadius: 4,
        borderBottom: '1px solid #ccc',
        width: '100%',
        display: 'flex',
        " & .question-block": {
            width: '98%'
        }
    },
    cursor: {
        cursor: 'pointer',
    },
    tableHeading: {
        fontSize: 16,
        color: '#000',
        fontWeight: 600,
        marginTop: 12,
        textDecoration: 'underline'
    },
    supportQuestionBlock: {
        padding: '8px',
        background: '#eee',
        borderRadius: '4px',
        " & .question": {

        },
        " & .answer": {
            paddingLeft: 8,
            fontSize: 12,
            fontWeight: 500,
            marginTop: 4,
            marginBottom: 4,
        }
    },
    heading: {
        marginBottom: 12,
        fontSize: 24,
        textAlign: 'center',
        marginTop: 16
    },
    infoMessage: {
        fontSize: 16,
        padding: '0px 12px 0px 12px',
        marginBottom: 12,
        marginTop: 8,
    },

    gridContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
        marginBottom: 8,
    },
    fieldContainer: {
        width: '100%',
        marginRight: 12,
        marginLeft: 12,
        " & .MuiFormControl-root": {
            marginBottom: 12,
            marginTop: 4,
        }
    },
    thumbnailUrl: {
        fontSize: 14,
        marginTop: 12,
    },
    clearIcon: {
        color: '#bd0912',
        marginLeft: 12,
        position: 'relative',
        top: '6px',
        margin: 'auto 0',
        cursor: 'pointer',
    },
    betaTag: {
        display: 'inline',
        border: 'solid #ce0000!important',
        borderRadius: '15px',
        fontSize: 'small',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        padding: '1px 10px',
        margin: '1px 1px 1px 4px',
        background: '#ce0000!important',
        color: 'white',
        verticalAlign: 'super'
    },
    dialog: {
        "@media(max-width:860px)": {
            position: 'absolute',
            left: 100,
            top: 150
        },
        "@media(min-width:860px) and (max-width:1024px)": {
            position: 'absolute',
            left: 200,
            top: 150
        }
    }
}));