import React, { useEffect, useState, useRef } from 'react';
import { HvButton, HvGrid, HvInput, HvTooltip, HvDropdown } from '@hitachivantara/uikit-react-core';
import Footer from 'components/footer/Footer';
import uuid from 'react-uuid';
import FileUploadService from 'services/FileUploadService';
import { Close } from '@hitachivantara/uikit-react-icons';
import { useDispatch } from 'react-redux';
import { buildQueryParams } from 'utils/common';
import LabMasterService from 'services/admin/masters/LabService';
import LabService from "services/LabService";
import { useStyles } from 'components/fileUpload/style'
import _ from "lodash";
import { toast } from "react-toastify";

const fileUploadServiceObj = new FileUploadService();
const ResourceSteps = props => {
    const labServiceObject = new LabMasterService();
    const labServiceObj = new LabService();
    const { currentStep } = props
    const [labId] = useState(props?.labData?.id);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [fileData, setFileData] = useState([]);
    const [resourceUrl, setResourceUrl] = useState("");
    const [resourceName, setResourceName] = useState("");
    const [selectedresourceType, setSelectedResourceType] = useState("");
    const [imageName, setImageName] = useState("");
    const [selectKey, setSelectKey] = useState(0);

    const [imagDescription, setImageDescritption] = useState("");
    const supportedFileTypes = ["doc", "docx", "xls", "xlsx", "pdf", "jpg", "jpeg", "png"];
    const [resourceType] = useState([
        { id: 1, label: 'Architecture Guide', value: 'Architecture Guide' },
        { id: 2, label: 'Blog', value: 'Blog' },
        { id: 3, label: 'Certification', value: 'Certification' },
        { id: 4, label: 'Customer Use Case', value: 'Customer Use Case' },
        { id: 5, label: 'Implementation Guide', value: 'Implementation Guide' },
        { id: 6, label: 'Interoperability', value: 'Interoperability' },
        { id: 7, label: 'Performance Report', value: 'Performance Report' },
        { id: 8, label: 'Reference Architecture', value: 'Reference Architecture' },
        { id: 9, label: 'Security', value: 'Security' },
        { id: 10, label: 'Sizing', value: 'Sizing' },
        { id: 11, label: 'Tool', value: 'Tool' },
        { id: 12, label: 'Video', value: 'Video' },
        { id: 13, label: 'White Paper', value: 'White Paper' }

    ]);
    const supportedFileMaxSize = 10;
    const myRef = useRef([]);
    const getALLResource = () => {
        const resourceUrlParams = { object_id: labId, is_deleted: false };
        fileUploadServiceObj.getResourceUrlByLabId(buildQueryParams(resourceUrlParams)).then(response => {
            if (response) {
                const { results } = response;
                if (results && results.length > 0) {
                    setFileData(results);
                } else { setFileData([]) }

            }
        }).catch(error => {
            console.log(error)
        })
    }
    useEffect(() => {
        if (labId > 0) {
            getALLResource();
        }

    }, [])

    const resourceUrlClick = (evt) => {
        if (_.isEmpty(resourceName) || _.isEmpty(resourceUrl)) {
            toast.error("Please enter Resource Name and Resource URL.")
        } else {
            fileUploadServiceObj.uploadResourceUrl({
                "url": resourceUrl,
                "object_id": labId,
                "name": resourceName,
                "resources_type": selectedresourceType
            }).then(response => {
                if (response) {
                    toast.success("Resource Url added.");
                    setResourceName("");
                    setResourceUrl("");
                    setSelectedResourceType("");
                    getALLResource();
                    setSelectKey(prevKey => prevKey + 1);

                }
            }).catch(err => {
                console.log(err)
            })
        }

    }
    const removeSelectedResourceUrl = (index, resourceId) => {
        fileUploadServiceObj.deleteResourceUrl(resourceId).then(response => {
            toast.error("Resource Url Removed");
            getALLResource();
        }).catch(error => {
            toast.error(error && error.url && error.url.toString());
        });
    }
    const removeSelectedFile = (index, resourceId) => {
        fileUploadServiceObj.getFileDeleteUrl(resourceId).then(response => {
            toast.error("File Removed");
            getALLResource();
        }).catch(error => {
            toast.error(error && error.url && error.url.toString());
        });
    }
    const handletagSave = () => {
        props.setNextStep(currentStep + 1);

    }
    const openFileInNewTab = (url) => {
        var sanitizeUrl = encodeURI(url);
        window.open(sanitizeUrl, '_blank').focus();
    }

    return (
        <div>
            <form autoComplete='on' onSubmit={(event) => {
                event.preventDefault();
                handletagSave()
            }}>
                <HvGrid container spacing={3}>
                    <HvGrid item xs={2} >
                        <HvInput
                            fullWidth
                            name="Name"
                            label="Name"
                            variant="outlined"
                            disabled={false}
                            classes={{ root: classes.textField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.inputLabel

                            }}
                            onChange={(e) => {
                                setResourceName(e.target.value)

                            }}
                            value={resourceName}
                        >
                        </HvInput>
                    </HvGrid>
                    <HvGrid item xs={4} >
                        <HvInput
                            fullWidth
                            name="resource_url"
                            label="Resource Url"
                            variant="outlined"
                            disabled={false}
                            classes={{ root: classes.textField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.inputLabel

                            }}
                            onChange={(e) => {
                                setResourceUrl(e.target.value)

                            }}
                            value={resourceUrl}
                        >
                        </HvInput>
                    </HvGrid>

                    <HvGrid item xs={4} >
                        {/* <HvLabel
                            className={classes.selectLabel} >Type</HvLabel> */}
                        <HvDropdown
                            key={selectKey}
                            classes={{ root: classes.textField }}
                            aria-label="With search"
                            showSearch
                            label="Type"
                            onChange={(valueObject) => {
                                setSelectedResourceType(valueObject?.value)

                            }}
                            values={resourceType}
                        />

                    </HvGrid>
                    <HvGrid item xs={2} style={{ marginTop: '42px' }} >
                        <HvButton
                            variant="primary"
                            component="label"
                            onClick={(e) => resourceUrlClick(e)}>
                            Add Resource Url
                        </HvButton>
                    </HvGrid>
                </HvGrid>
                <HvGrid container spacing={2} style={{ paddingTop: '16px' }}>
                    <HvGrid item xs={12} className={classes.urlContainer}>
                        {
                            fileData.length > 0 ?
                                fileData && fileData.length > 0 && fileData.map((resource, index) => {
                                    if (!_.isNull(resource?.url)) {
                                        return (
                                            <div key={uuid()} style={{ display: 'flex', marginBottom: 6, gap: 10 }}>

                                                <div>Resource Name : {resource?.name}</div>
                                                <div>Resource Type : {resource?.resources_type}</div>
                                                <div className={classes.fileName} onClick={() => openFileInNewTab(resource.url)}>{resource.url.length > 100 ? resource.url.slice(0, 100) + "..." : resource.url}</div>

                                                <HvTooltip title="Remove Resource"> <Close style={{ '--color-0': 'red' }} className={classes.clearIcon} onClick={() => removeSelectedResourceUrl(index, resource.id)} /> </HvTooltip>

                                            </div>
                                        )
                                    }

                                })
                                : <></>
                        }
                    </HvGrid>
                    <HvGrid item xs={4} className={classes.urlContainer}>
                        {fileData && fileData.length > 0 && fileData.map((file, index) => {
                            if (!_.isNull(file?.file))
                                return (
                                    <div key={uuid()} style={{ display: 'flex', marginBottom: 6, gap: 10 }}>
                                        <div>File Name : {file.name}</div>
                                        <div>File description : {file.description}</div>
                                        <div className={classes.fileName} onClick={() => openFileInNewTab(file.file)}>{file.file_type}
                                        </div>
                                        <Close className={classes.clearIcon} onClick={() => removeSelectedFile(index, file.id)} />
                                    </div>
                                )
                        })}
                    </HvGrid>
                </HvGrid>
                < Footer handleSumbit={(event) => { event?.preventDefault() }} />
            </form >
        </div >
    );
};

ResourceSteps.propTypes = {

};

export default ResourceSteps;