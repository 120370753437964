import React, { useEffect, useRef, useState } from 'react';
import {
    HvGrid, HvDatePicker, HvTypography, HvButton,
    HvTableContainer, HvTable,
    HvTableHead, HvTableRow,
    HvTableHeader, HvTableBody,
    HvTableCell, HvPagination, HvTooltip,
    HvDialog, HvDialogActions, HvDialogTitle,
    HvDialogContent
} from '@hitachivantara/uikit-react-core';
import { useSelector } from 'react-redux';
import LabService from 'services/LabService';
import _ from 'lodash';
import { buildQueryParams } from 'utils/common';
import { withRouter } from 'react-router';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showBanner } from 'redux/actions/bannerActions';
import { Backwards } from '@hitachivantara/uikit-react-icons';
import { useHistory } from 'react-router';
import { useStyles } from './style';
import { LOGGED_IN_USER_INFO, DOWNLOAD_AGREEMENT_MESSAGE, HALO_SUPPORT_TEAM } from 'constants/common';
import { showLoader } from 'redux/actions/commonActions';
import { CloseXS } from '@hitachivantara/uikit-react-icons';


const LabScheduler = ({ history }) => {
    const labServiceObj = new LabService();
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [availableSlots, setAvailbleSlots] = useState([]);
    const { location } = history;
    const state = location && location?.state;
    const [labDetail] = useState(!_.isNull(state) ? state?.labDetail : "");
    const userInfoDetails = useSelector(state => state.loginReducer.userInfo);
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(null);
    const dispatch = useDispatch();
    const hookHistory = useHistory();
    const userInfo = useRef();
    const pdfObjectRef = useRef(null);
    userInfo.current = userInfoDetails?.id ? userInfoDetails : JSON.parse(sessionStorage.getItem("HALO-USER-INFO"));
    const token = JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO));
    const pageSizeOptions = [1, 2, 3, 4, 6, 8];
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
    const numPages = availableSlots.length === 1 ? 1 : Math.ceil(availableSlots.length / pageSize);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState();
    const [selectedSlotDate, setSelectedSlotDate] = useState();
    const [displaySelectedSlot, setDisplaySelectedSlot] = useState();
    const [cancelDialog, setCancelDialog] = useState(false);
    const [slotId, setSlotId] = useState();
    const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
    const [agreementPDF, setAgreementPDF] = useState(false);
    const [agreementId, setAgreementId] = useState(false);
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
    const [agreementUrl, setAgreementUrl] = useState(false);
    const [acceptedAgreement, setAcceptedAgreement] = useState(false);
    const classes = useStyles();


    useEffect(() => {
        if (_.isEmpty(token)) {
            history.push("/email-login");
            return; // Stop further execution if token is empty
        }
        if (_.isEmpty(agreementPDF) && acceptedAgreement === false) {
            let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
            let tokenData = data ? JSON.parse(data) : {};
            const hasPartner = tokenData?.groups?.some(item => item.name === "Partners"); // Using some() instead of map()
            if (hasPartner) {
                setAcceptedAgreement(true);
                labServiceObj.userAgreement().then((response) => {
                    if (_.isEmpty(response.data.results)) {
                        let params = {
                            name: "Partner"
                        };
                        labServiceObj.getUserAgreement(buildQueryParams(params)).then(agreementResponse => {
                            setAgreementPDF(agreementResponse.data.results[0].content_data);
                            setAgreementId(agreementResponse.data.results[0].id);
                            setAgreementUrl(agreementResponse.data.results[0].content);
                            setOpenAgreementDialog(true);
                        }).catch(error => {
                            console.log(error);
                        });
                    } else {
                        setAcceptedAgreement(true);
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [token, acceptedAgreement]);


    const sendAgreementData = () => {
        const payload = {
            "agreement": agreementId,
            "user_agree": true
        }
        setButtonEnabled(true);
        setOpenAgreementDialog(false);
        labServiceObj.acceptUserAgreement(payload).then(response => {
            dispatch(showLoader(true));
            if (response) {
                dispatch(showLoader(false));
                setAcceptedAgreement(true);
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Thank you for accepting agreement. You can schedule the Lab." },
                        variant: "success"
                    }
                }))
            }
        })
    }


    const style = {
        border: `solid 1px rgb(204, 206, 208)`,
    };

    const getColumns = () => [
        {
            header: "Date",
            headerText: "date",
            accessor: "date",
            cellType: "date",
        },
        {
            header: "Slots",
            headerText: "slots",
            accessor: "time",
            cellType: "alpha-numeric",
        },

    ];
    const columns = getColumns();
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatTime(inputDate, hours = 0, minutes) {
        const date = new Date(inputDate);
        date.setHours(hours, minutes, 0, 0);
        const hoursFormatted = date.getHours().toString().padStart(2, '0');
        const minutesFormatted = date.getMinutes().toString().padStart(2, '0');
        const secondsFormatted = date.getSeconds().toString().padStart(2, '0');
        return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
    }

    function generateOutput(fromDate, toDate) {
        const currentDate = new Date();
        const formattedCurrentDate = formatDate(currentDate);
        const formattedFromDate = formatDate(fromDate);
        const formattedToDate = formatDate(toDate);

        const output = {};

        if (formattedFromDate === formattedCurrentDate) {
            output.from_date = formattedFromDate + ' ' + formatTime(currentDate, currentDate.getHours());
            output.from_date = formattedFromDate + ' ' + formatTime(currentDate, currentDate.getHours(), currentDate.getMinutes());
            output.to_date = formattedToDate + ' ' + '23:59:00';
        } else if (formattedFromDate > formattedCurrentDate) {
            output.from_date = formattedFromDate + ' ' + formatTime(fromDate, 0, 0);
            output.to_date = formattedToDate + ' ' + '23:59:00';
        } else if (formattedFromDate === formattedToDate) {
            output.from_date = formattedFromDate + ' ' + formatTime(fromDate, 0, 0);
            output.to_date = formattedToDate + ' ' + '23:59:00';
        }

        return output;
    }

    const validateDate = (fromDate, toDate) => {
        const today = new Date();

        if (!fromDate || !toDate) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Both from and to dates are required." },
                    variant: "error"
                }
            }))
            return false
        }

        if (fromDate > toDate) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "From date cannot be after the to date." },
                    variant: "error"
                }
            }))
            return false;
        }

        if (fromDate < formatDate(today)) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "From date cannot be in the past." },
                    variant: "error"
                }
            }))
            return false;
        }

        return true;
    }
    const getAvailableSlots = () => {
        if (!_.isNull(selectedDate) && !_.isNull(selectedToDate)) {
            const checkForValidDates = validateDate(formatDate(selectedDate), formatDate(selectedToDate));
            if (checkForValidDates) {
                const dateSlots = generateOutput(selectedDate, selectedToDate)
                const payload = {
                    ...dateSlots,
                    lab: labDetail.id,
                    user: userInfo?.current.id,
                    timezone: encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)
                }
                labServiceObj.getAvailableSlots(buildQueryParams(payload)).then(response => {
                    if (response) {
                        if (response.data[0]?.available_slots?.length === 0) {
                            dispatch(showBanner({
                                payload: {
                                    showBanner: true,
                                    label: { message: "No Slots Available." },
                                    variant: "error"
                                }
                            }))
                        } else {
                            setAvailbleSlots(response.data[0].available_slots)
                        }

                    }
                })
            }
        } else {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Both from and to dates are required." },
                    variant: "error"
                }
            }))
        }

    }


    function extractDateTime(dateTimeString) {
        // Split the input string by space to separate date and time
        const parts = dateTimeString.split(' ');

        // Check if there is a valid date and time part
        if (parts.length >= 2) {
            // Extract the date and time part
            const datePart = parts[0];
            const timePart = parts[1];

            // Split the time part by the '+' or '-' symbol
            const timeParts = timePart.split(/[+-]/);

            // Check if there is a valid time part
            if (timeParts.length >= 1) {
                // Extract the time without the timezone offset
                const timeWithoutOffset = timeParts[0];

                // Return the formatted date and time
                return `${datePart} ${timeWithoutOffset}`;
            }
        }

        // Return an empty string if the input format is not as expected
        return '';
    }

    function extractScheduleTimes(timeString) {
        // Split the input string by the ' - ' symbol to separate the schedule_from and schedule_to parts
        const scheduleParts = timeString.split(' - ');

        // Check if there are two valid schedule parts
        if (scheduleParts.length === 2) {
            const schedule_from = extractDateTime(scheduleParts[0]);
            const schedule_to = extractDateTime(scheduleParts[1]);

            return {
                schedule_from,
                schedule_to,
            };
        }

        // Return an empty object if the input format is not as expected
        return {};
    }

    const bookSlot = (originalString) => {
        setConfirmDialog(!confirmDialog);
        const bookedSlots = extractScheduleTimes(originalString)
        const payload = {
            lab: labDetail?.id,
            user: userInfo?.current?.id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...bookedSlots
        }
        dispatch(showLoader(true));
        labServiceObj.bookSelectedSlot(payload).then(response => {
            if (response) {
                dispatch(showLoader(false));
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Lab is Scheduled." },
                        variant: "success"
                    }
                }))
                getAvailableSlots(selectedToDate);

            }
        })
    }

    const cancelSlot = (id) => {
        setCancelDialog(!cancelDialog);
        dispatch(showLoader(true));
        labServiceObj.cancelSelectedSlot(id).then(response => {
            dispatch(showLoader(false));
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Slot Cancelled." },
                    variant: "success"
                }
            }))
            getAvailableSlots(selectedToDate);

        }).catch(error => {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: error?.message },
                    variant: "error"
                }
            }))
        })
    }

    function convertTo12HourFormat(timeRange) {
        // Split the input by the hyphen to get the start and end times
        const [startTime, endTime] = timeRange.split(' - ');

        // Split the start and end times by the colon to get hours and minutes
        const [startHour, startMinute] = startTime.split(':');
        const [endHour, endMinute] = endTime.split(':');

        // Convert the hours to numbers
        const startHourNum = parseInt(startHour, 10);
        const endHourNum = parseInt(endHour, 10);

        // Determine AM or PM for both times
        const startPeriod = startHourNum >= 12 ? 'PM' : 'AM';
        const endPeriod = endHourNum >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        const startHour12 = startHourNum === 0 ? 12 : (startHourNum > 12 ? startHourNum - 12 : startHourNum);
        const endHour12 = endHourNum === 0 ? 12 : (endHourNum > 12 ? endHourNum - 12 : endHourNum);

        // Create formatted strings for start and end times
        const formattedStartTime = `${startHour12}:${startMinute} ${startPeriod}`;
        const formattedEndTime = `${endHour12}:${endMinute} ${endPeriod}`;

        // Return the formatted time range
        return `${formattedStartTime} - ${formattedEndTime}`;
    }

    const clearAllDatesSlots = () => {
        setAvailbleSlots([]);
        setSelectedToDate(null);
        setSelectedDate(null);
    }

    const handleScroll = (e) => {
        const pdfContainer = e.target;
        // Check if the user has scrolled to the bottom of the PDF
        const threshold = 10;
        if (pdfContainer.scrollHeight - (pdfContainer.scrollTop + pdfContainer.clientHeight) <= threshold) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false)
        }
    };


    const downloadAgreemt = () => {
        fetch(agreementUrl)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a blob URL for the PDF
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = "downloaded-file.pdf"; // Specify the filename for the downloaded file

                // Trigger a click event to initiate the download
                a.click();

                // Revoke the blob URL to free up resources
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error downloading the PDF: ", error);
            });
    }

    return (
        <div style={{ height: '100%' }} >
            <Box padding={"10px"} display={"flex"} gap={5}>
                <HvTypography variant='mTitle' style={{ display: "flex" }}> <Backwards onClick={() => { hookHistory.goBack() }} style={{ cursor: "pointer" }} /> {labDetail?.name}</HvTypography>
                <HvTypography variant='selectedNavText' style={{ display: "flex", padding: "10px" }} >Time Zone: {Intl.DateTimeFormat().resolvedOptions().timeZone} </HvTypography>
            </Box>
            <Box padding={"10px"}>
                <HvTypography variant='xxsTitle'>To Get the Available Slots, Please Select From and To Date. </HvTypography>
            </Box>
            <Box>

            </Box>
            <HvGrid container>
                <HvGrid item xs={12} container alignItems={"start"} alignContent={"start"}>
                    <HvGrid item xs={4} sm={4} md={4} rowSpacing={4}>
                        <HvGrid item style={{ padding: '10px' }} xs={6} sm={6} md={6}>
                            <HvDatePicker
                                description="Please select from date"
                                placeholder="Choose a date"
                                required
                                aria-errormessage="date-error"
                                onChange={(value) => {
                                    if (formatDate(value) >= formatDate(currentDate)) {
                                        setSelectedDate(value);
                                    } else {
                                        setSelectedDate(value);
                                        dispatch(showBanner({
                                            payload: {
                                                showBanner: true,
                                                label: { message: "Invalid From Date. Please select a future or present date." },
                                                variant: "error"
                                            }
                                        }))


                                    }
                                }}
                                value={selectedDate}
                            />
                        </HvGrid>
                        <HvGrid item style={{ padding: '10px' }} xs={6} sm={6} md={6}>
                            <HvDatePicker
                                description="Please select to date"
                                placeholder="Choose a date"
                                required
                                aria-errormessage="date-error"
                                onChange={(value) => {
                                    if (formatDate(value) >= formatDate(currentDate) && formatDate(value) >= formatDate(selectedDate)) {
                                        setSelectedToDate(value);
                                    } else {
                                        setSelectedToDate(value);
                                        dispatch(showBanner({
                                            payload: {
                                                showBanner: true,
                                                label: { message: "Invalid To Date. Please select a future or present date." },
                                                variant: "error"
                                            }
                                        }))
                                    }
                                }}
                                value={selectedToDate}
                            />
                        </HvGrid>
                        <HvGrid container style={{ padding: '30px' }} item xs={10} >
                            <HvGrid style={{ padding: '10px' }} item xs={5} sm={5} md={5}>
                                <HvButton onClick={getAvailableSlots}>Get Slots</HvButton>
                            </HvGrid>
                            <HvGrid style={{ padding: '10px' }} item xs={5} sm={5} md={5}>
                                <HvButton category='secondary' onClick={clearAllDatesSlots}>Clear</HvButton>
                            </HvGrid>
                        </HvGrid>
                    </HvGrid>

                    <HvGrid item xs={4} container alignContent={'center'} style={{ paddingTop: '70px' }}>
                        <HvTableContainer >
                            <HvTable>
                                <HvTableHead>
                                    <HvTableRow style={{
                                        verticalAlign: "top",
                                        ...style,
                                    }}>
                                        {availableSlots.length > 0 ?
                                            columns.map((el, index) => (
                                                <HvTableHeader key={el.header} >
                                                    {el.header}
                                                </HvTableHeader>
                                            )) : <></>}
                                    </HvTableRow>
                                </HvTableHead>
                                <HvTableBody>
                                    {availableSlots.length > 0 ?
                                        availableSlots.slice(pageSize * page, pageSize * (page + 1)).map((el, index) => {
                                            return (
                                                <>

                                                    {
                                                        <HvTableRow key={index}>
                                                            <HvTableCell
                                                                rowSpan={el.time.length + 1}
                                                                style={{
                                                                    verticalAlign: "top",
                                                                    ...style

                                                                }}
                                                            >
                                                                {el.date}
                                                            </HvTableCell>
                                                            {el?.StatusOfFirstSlot === "Available" ? <>
                                                                <HvTooltip title={<HvTypography>Available</HvTypography>}>
                                                                    <HvButton category='primary' className={classes.hvButtonPrimaryScheduler} onClick={() => {
                                                                        setDisplaySelectedSlot(convertTo12HourFormat(el.firstSlot))
                                                                        setSelectedSlot(el.firstSlotoriginalString);
                                                                        setConfirmDialog(!confirmDialog);
                                                                        setSelectedSlotDate(el.date);

                                                                    }} style={{ ...style, width: '100%' }}>{convertTo12HourFormat(el.firstSlot)}</HvButton>
                                                                </HvTooltip>

                                                            </> :
                                                                <Box display={"flex"}>
                                                                    <HvTooltip title={<HvTypography>Booked</HvTypography>}>
                                                                        <HvButton variant="primaryGhost" className={classes.hvButtonGhostScheduler} style={{ ...style, width: '100%', paddingLeft: '78px' }}
                                                                            onClick={() => {
                                                                                setDisplaySelectedSlot(convertTo12HourFormat(el.firstSlot))
                                                                                setSelectedSlotDate(el?.date)
                                                                            }}> {convertTo12HourFormat(el.firstSlot)}
                                                                        </HvButton>
                                                                    </HvTooltip>
                                                                    <HvTooltip title={<HvTypography>Cancel</HvTypography>}>
                                                                        <HvButton variant="primaryGhost" className={classes.hvButtonGhostScheduler} style={{ ...style }}>
                                                                            <CloseXS onClick={() => {
                                                                                setCancelDialog(!cancelDialog); setSlotId(el?.firstSlotId); setDisplaySelectedSlot(convertTo12HourFormat(el.firstSlot))
                                                                                setSelectedSlotDate(el?.date)
                                                                            }} />
                                                                        </HvButton>
                                                                    </HvTooltip>
                                                                </Box>
                                                            }

                                                        </HvTableRow>
                                                    }
                                                    {el.time.length > 0 && el.time.map((e2) => {
                                                        if (e2?.status === "Available") {
                                                            return (
                                                                <HvTableRow>
                                                                    <HvTooltip title={<HvTypography>Available</HvTypography>}>
                                                                        <HvButton category='primary' onClick={() => {
                                                                            setDisplaySelectedSlot(convertTo12HourFormat(e2.slots))
                                                                            setSelectedSlot(e2.originalString);
                                                                            setConfirmDialog(!confirmDialog);
                                                                            setSelectedSlotDate(el.date);
                                                                        }} className={classes.hvButtonPrimaryScheduler} style={{ ...style, width: '100%' }}>{convertTo12HourFormat(e2.slots)}  </HvButton>
                                                                    </HvTooltip>
                                                                </HvTableRow>
                                                            )
                                                        } else {
                                                            return (
                                                                <HvTableRow>
                                                                    <Box display={"flex"}>
                                                                        <HvTooltip title={<HvTypography>Booked</HvTypography>}>
                                                                            <HvButton variant="primaryGhost" className={classes.hvButtonGhostScheduler} style={{ ...style, width: '100%', paddingLeft: '78px' }} onClick={() => {
                                                                                setDisplaySelectedSlot(convertTo12HourFormat(e2.slots))
                                                                                setSelectedSlotDate(el.date)
                                                                            }}> {convertTo12HourFormat(e2.slots)}
                                                                            </HvButton>
                                                                        </HvTooltip>
                                                                        <HvTooltip title={<HvTypography>Cancel</HvTypography>}>
                                                                            <HvButton variant="primaryGhost" className={classes.hvButtonGhostScheduler} style={{ ...style }}>
                                                                                <CloseXS onClick={() => {
                                                                                    setCancelDialog(!cancelDialog); setSlotId(e2?.slotId); setDisplaySelectedSlot(convertTo12HourFormat(e2.slots))
                                                                                    setSelectedSlotDate(el.date)
                                                                                }} />
                                                                            </HvButton>
                                                                        </HvTooltip>
                                                                    </Box>
                                                                </HvTableRow>
                                                            )
                                                        }

                                                    })}
                                                </>
                                            )
                                        }) : <></>}
                                </HvTableBody>
                            </HvTable >


                        </HvTableContainer>

                    </HvGrid>
                    {
                        availableSlots.length > 0
                            ?
                            <HvGrid item xs={3} container alignContent={'end'} spacing={2} style={{ paddingTop: '70px' }}>
                                <HvTableContainer >
                                    <HvTable>
                                        <HvTableBody>
                                            <HvTableRow>
                                                <HvTableCell style={{
                                                    verticalAlign: "top",
                                                    ...style

                                                }}>
                                                    <HvButton category='primary' className={classes.hvButtonPrimaryScheduler} style={{ ...style, width: '100%' }} />
                                                </HvTableCell>
                                                <HvTableCell style={{
                                                    verticalAlign: "top",
                                                    ...style

                                                }}>Available</HvTableCell>
                                            </HvTableRow>
                                            <HvTableRow>
                                                <HvTableCell style={{
                                                    verticalAlign: "top",
                                                    ...style

                                                }}>
                                                    <HvButton variant="primaryGhost" className={classes.hvButtonGhostScheduler} style={{ ...style, width: '100%' }} />
                                                </HvTableCell>
                                                <HvTableCell style={{
                                                    verticalAlign: "top",
                                                    ...style

                                                }}>Booked</HvTableCell>
                                            </HvTableRow>
                                        </HvTableBody>
                                    </HvTable>
                                </HvTableContainer>
                            </HvGrid>
                            : <></>
                    }
                    {
                        confirmDialog ? <>
                            <HvDialog
                                disableBackdropClick
                                open={confirmDialog}
                                onClose={() => setConfirmDialog(false)}
                                aria-labelledby="hv-dialog-title"
                                aria-describedby="hv-dialog-description"
                                id="test"
                                firstFocusable="test-close"
                            >
                                <HvDialogTitle id="hv-dialog-title" variant="warning">
                                    Book Slot
                                </HvDialogTitle>
                                <HvDialogContent id="hv-dialog-description" indentContent>
                                    Do you want to book this slot  {displaySelectedSlot} of date {selectedSlotDate}..?
                                </HvDialogContent>
                                <HvDialogActions>
                                    <HvButton id="apply" category="ghost" onClick={() => bookSlot(selectedSlot)}>
                                        Book Slot
                                    </HvButton>
                                </HvDialogActions>
                            </HvDialog>
                        </> : <></>
                    }
                    {
                        cancelDialog ? <>
                            <HvDialog
                                disableBackdropClick
                                open={cancelDialog}
                                onClose={() => setCancelDialog(false)}
                                aria-labelledby="hv-dialog-title"
                                aria-describedby="hv-dialog-description"
                                id="test"
                                firstFocusable="test-close"
                            >
                                <HvDialogTitle id="hv-dialog-title" variant="warning">
                                    Cancel Slot
                                </HvDialogTitle>
                                <HvDialogContent id="hv-dialog-description" indentContent>
                                    Do you want to  cancel this slot  {displaySelectedSlot} of date {selectedSlotDate}..?
                                </HvDialogContent>
                                <HvDialogActions>
                                    <HvButton id="apply" category="ghost" onClick={() => cancelSlot(slotId)}>
                                        Cancel Slot
                                    </HvButton>
                                </HvDialogActions>
                            </HvDialog>
                        </> : <></>
                    }
                </HvGrid>
            </HvGrid>
            {
                availableSlots?.length > 0 ?
                    <HvPagination
                        id="pagination"
                        pages={numPages}
                        page={page}
                        canPrevious={page > 0}
                        canNext={page < numPages - 1}
                        pageSize={pageSize}
                        pageSizeOptions={pageSizeOptions}
                        onPageChange={(value) => setPage(value)}
                        onPageSizeChange={(value) => setPageSize(value)}
                        labels={{ pageSizeEntryName: "items" }}
                        showPageJump={true}
                        className={classes.hvPagination}
                    /> : <></>
            }
            {
                openAgreementDialog ? <>
                    <HvDialog
                        disableBackdropClick
                        fullScreen={false}
                        style={{ height: "100%" }}
                        open={openAgreementDialog}
                        onClose={() => {
                            history.push("/labs")
                            setOpenAgreementDialog(false)
                        }}
                        aria-labelledby="hv-dialog-title"
                        aria-describedby="hv-dialog-description"
                        id="test"
                        autoFocus="true"
                    >
                        <HvDialogTitle id="hv-dialog-title" variant="warning">
                            Terms and Conditions
                        </HvDialogTitle>
                        <HvDialogContent indentContent onScroll={(e) => { handleScroll(e) }}>
                            <div id="pdf-container" style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: agreementPDF }} />
                        </HvDialogContent>

                        <HvDialogActions>
                            <HvButton id="apply" category="ghost" onClick={sendAgreementData} disabled={!isButtonEnabled}>
                                I Accept
                            </HvButton>
                            <HvButton id="apply" category="ghost" onClick={() => { setOpenDeclineDialog(true) }} disabled={!isButtonEnabled}>
                                Decline
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>

                </> : <></>
            }
            {
                openDeclineDialog ?
                    <HvDialog
                        disableBackdropClick
                        fullScreen={false}
                        open={openDeclineDialog}
                        onClose={() => {
                            history?.push("/labs")
                            setOpenDeclineDialog(false)
                        }}
                        aria-labelledby="hv-dialog-title"
                        aria-describedby="hv-dialog-description"
                        id="test"
                        firstFocusable="test-close"
                    >
                        <HvDialogContent indentContent >
                            <HvTypography variant='normalText' style={{ padding: "10px" }}> {DOWNLOAD_AGREEMENT_MESSAGE} <b>{HALO_SUPPORT_TEAM}</b></HvTypography>
                        </HvDialogContent>


                    </HvDialog> : <></>
            }
        </div>
    );
};



export default withRouter(LabScheduler);